import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { appStore } from "../store";
import { toggleModalGeneric } from "../actions/modalGeneric";
import { handleKeyPress, translate } from "../services/tools";
import axios from "../middlewares/axios";

class ModalGeneric extends Component {
  scrollingElement = null;
  modal = null;

  state = {
    content: null,
  };

  async componentDidMount() {
    const { language, modalFile } = this.props;

    this.modal = document.querySelector("[data-lc-modal]");

    // Add active class to modal
    this.modal.classList.add("lc-active");

    // Load text to display
    try {
      const response = await axios.get(`/api/file?folder=modals&ext=html&name=${modalFile}_${language}`);

      if (modalFile === "legal-notice") {
        const cookiesValue = appStore.getState().app.hasAuthorizedCookies;

        this.setState({
          content: response.data.replace(
            "VALUE_COOKIE",
            translate(`legal-notice-${cookiesValue ? "cookies-refuse" : "cookies-accept"}`)
          ),
        });
      } else {
        this.setState({ content: response.data });
      }
    } catch (e) {
      console.warn(`Can't load : ${modalFile}_${language}`, e);
      return;
    }
  }

  hide = () => {
    const { modalFile } = this.props;

    // Add hide class to modal
    this.modal.classList.remove("lc-active");

    setTimeout(() => {
      appStore.dispatch(toggleModalGeneric(modalFile));
    }, 250);
  };

  render() {
    const { modalFile } = this.props;

    if (!process.env.REACT_APP_LIBRARY_URL) {
      // Temporary fix. TODO : change all index.html with a portal ID of lcmap-modal
      document.getElementById("portal") && document.getElementById("portal").setAttribute("id", "lcmap-modal");
    }

    return ReactDOM.createPortal(
      <div className={`lc-modal ${modalFile}`} data-lc-modal>
        <div className="lc-head">
          <div
            className={`lc-close ${modalFile}`}
            onClick={this.hide}
            onKeyPress={(e) => handleKeyPress(e, this.hide)}
            role="button"
            tabIndex="0"
          />
          <div className="lc-modal-content lc-scroll" data-lc-scroll="scroll">
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.content,
              }}
            />
          </div>
        </div>
      </div>,
      document.getElementById("lcmap-modal")
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.modalGeneric,
    language: state.app.language,
  };
};

export default connect(mapStateToProps)(ModalGeneric);
