import {
  actionMarkerClick,
  actionSetLineSelected,
  actionSetMapPlaces,
  actionSetPublicPlaces,
  actionSetTransportPlaces,
  actionSetLineSelectedPath,
  actionSetCustomMarkers,
} from "../actions/map";
import {
  buildHeavyLines,
  buildCustomLines,
  buildMarker,
  buildCustomMarker,
  buildPlaces,
  onLineSelected,
  onMarkerMouseOut,
  onMarkerMouseOver,
  onOpenMarker,
  buildLinePath,
  buildBikePaths,
  buildMapBikes,
  buildAllLines,
} from "../services/map";
import { getURLSearchParams, addGetParam, getRef, isActiveModule } from "../services/tools";
import { schedules, fitBounds } from "../utils/leaflet/map";
import history from "../history";

const { REACT_APP_NEXT_SCHEDULES_RESULTS, REACT_APP_ALL_POPUP_ON_TOP, REACT_APP_POPUP_OFFSET } = process.env;
const nextSchedules = REACT_APP_NEXT_SCHEDULES_RESULTS ? REACT_APP_NEXT_SCHEDULES_RESULTS : 2;

export default () => {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      const { component } = getState().app;
      const { pathname } = history.location;
      const params = getURLSearchParams(history.location);

      switch (action.type) {
        case "BUILD_MARKER":
          return buildMarker(getState(), action.data, action.options);

        case "BUILD_CUSTOM_MARKER":
          return buildCustomMarker(action.data, action.options);

        case "OVER_MARKER":
          onMarkerMouseOver(getState(), action.data);
          break;

        case "OUT_MARKER":
          onMarkerMouseOut(getState(), action.data);
          break;

        case "OPEN_MARKER":
          const { isMobile } = getState().app;
          const { openedMarker, lineSelected, transportPlaces } = getState().map;
          const map = getState().app.map.mapReference.current.leafletElement;

          // Close current opened marker
          if (openedMarker && !openedMarker.ref) {
            // divIcon here ? Must be on transportPlaces
            if (openedMarker.divIcon) {
              openedMarker.ref = getRef(openedMarker, transportPlaces);
            }
          }

          openedMarker && openedMarker.ref && openedMarker.ref.leafletElement.closePopup();

          if (!action.data) {
            dispatch(actionMarkerClick(null));
          } else {
            // If we have a line selected in the state, open the marker's schedules
            if (nextSchedules > 0 && lineSelected && action.data.lines && !action.data.severity) {
              schedules(component, action.data, lineSelected);
            } else {
              if (component?.state?.stopsList) {
                const { stopsList } = component.state;

                for (const s of stopsList) {
                  s.opened = false;

                  if (action.data.id.includes("stop_area")) {
                    s.opened = s.stop_area === action.data.id;
                  } else {
                    s.opened = s.id === action.data.id;
                  }
                }

                component.setState({ stopsList });
              }
            }

            // TODO Mobile, close popup if drag pin ?
            const popup = document.querySelector(".leaflet-popup");
            // Detect if we have no board, if no, no left offset
            const noBoard = document.querySelector(".lc-no-board");

            const offset = isMobile
              ? [
                  popup && !action.data.divIcon && !REACT_APP_ALL_POPUP_ON_TOP ? -popup.offsetWidth / 2 : 0,
                  REACT_APP_POPUP_OFFSET ? parseInt(JSON.parse(REACT_APP_POPUP_OFFSET).offset.top) : 0,
                ]
              : [noBoard ? 0 : 440 / 2, 0];

            if (
              !action.data.clusterized &&
              !isActiveModule("around") &&
              !component?.props?.moduleData?.blockZoomAtStop
            ) {
              const { markerModeChanged } = component.props.moduleData || component.props;
              const zoom = action.data.divIcon ? Math.max(markerModeChanged, map.getZoom()) : map.getZoom();
              const targetPoint = map.project([action.data.coord.lat, action.data.coord.lon], zoom).subtract(offset);
              const targetLatLng = map.unproject(targetPoint, zoom);

              if (!action.data.forceNotFitBounds) {
                map.setView(targetLatLng, zoom);
              }
            }

            dispatch(actionMarkerClick(action.data));
            onOpenMarker(getState(), action.data);
          }

          if (
            action.data &&
            action.data.id &&
            action.data.stop_area &&
            params.stop &&
            params?.stop !== action.data.id &&
            params?.stop !== action.data.stop_area
          ) {
            const searchParam = addGetParam(params, { stop: action.data.id });

            history.push({
              pathname,
              search: searchParam,
            });
          }

          break;

        // TODO ! recode utils/map.js functions...
        case "ON_LINE_SELECTED":
          // TODO mutual "onLineSelected" func
          // component.onLineSelected(action.line, action.data, action.onLoad)
          dispatch(actionSetLineSelected(action.line));
          // Display line path
          action.line
            ? buildLinePath(action.line, getState().app.hash).then((polyline) => {
                if (polyline && polyline.props.data.features && action.line.tad && action.line.tad.zone) {
                  dispatch(actionSetLineSelectedPath(polyline));

                  const stopsToZoom = [];

                  if (action.line.stops) {
                    for (const lineStop of action.line.stops) {
                      stopsToZoom.push([lineStop.coord.lat, lineStop.coord.lon]);
                    }

                    fitBounds(getState().app.map, stopsToZoom);
                  }
                } else if (polyline && polyline.props.data.features) {
                  dispatch(actionSetLineSelectedPath(polyline));

                  if (!action.data || !action.data.name) {
                    fitBounds(getState().app.map, [polyline]);
                  }
                } else if (!(isActiveModule("around") && action.line?.tad?.trace === false)) {
                  const stopsToZoom = [];

                  if (action.line.stops) {
                    for (const lineStop of action.line.stops) {
                      stopsToZoom.push([lineStop.coord.lat, lineStop.coord.lon]);
                    }

                    fitBounds(getState().app.map, stopsToZoom);
                  }
                }
              })
            : dispatch(actionSetLineSelectedPath(null));

          // Dispatch service's onLineSelected only if we have some data
          action.line && onLineSelected(getState(), action.line, action.data, action.openInfobox);
          break;

        case "BUILD_HEAVY_LINES":
          buildHeavyLines(getState(), action.zoom);
          break;

        case "BUILD_CUSTOM_LINES":
          buildCustomLines(getState(), action.lines);
          break;

        case "BUILD_TRANSPORT_PLACES":
          if (action.isLib) {
            dispatch(actionSetCustomMarkers(buildPlaces(getState(), action.places)));
          } else {
            dispatch(actionSetTransportPlaces(buildPlaces(getState(), action.places)));
          }

          break;

        case "BUILD_MAP_PLACES":
          dispatch(actionSetMapPlaces(buildPlaces(getState(), action.places)));
          break;

        case "BUILD_PUBLIC_PLACES":
          dispatch(actionSetPublicPlaces(buildPlaces(getState(), action.places)));
          break;

        case "BUILD_BIKE_PATHS":
          buildBikePaths(action.files);
          break;

        case "BUILD_MAP_BIKES":
          buildMapBikes(getState(), action.bikes);
          break;

        case "SET_ALL_LINES_SELECTED":
          buildAllLines(getState().app.map, action.selected);
          break;

        default:
          break;
      }

      return next(action);
    };
};
