import { useEffect, useState } from "react";
import axios from "../../middlewares/axios";
import { useLocation } from "react-router-dom";

const useSelectedFocus = (selectedFocus) => {
  const [datas, setDatas] = useState([]);
  const [datasGeojson, setDatasGeojson] = useState({});
  const [resultGeojsons, setResultGeojsons] = useState({});
  const [resultInfos, setResultInfos] = useState({});
  const location = useLocation();

  useEffect(() => {
    const loadData = async () => {
      // à mettre plutot dans moduleData.datas ?
      const datasResponse = await axios.get(`/api/file?ext=json&name=interactiveMapObjectsData`);

      setDatas(datasResponse.data);
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadGeojson = async () => {
      let geojsons = {};

      for (const data of Object.keys(datas)) {
        if (!datas[data].tiles) {
          const response = await axios.get(`/api/file?folder=map&ext=geojson&name=${data}`);

          geojsons[data] = response.data;
        }
      }

      setDatasGeojson(geojsons);
    };

    loadGeojson();

    let result = {};

    for (const key of Object.keys(datas)) {
      result[key] = datas[key].infos;
    }

    setResultInfos(result);
  }, [datas, location]);

  useEffect(() => {
    if (selectedFocus !== null && selectedFocus !== undefined) {
      let result = {};

      for (const key of Object.keys(datas)) {
        if (datas[key].focus?.includes(selectedFocus)) {
          result[key] = datasGeojson[key];
        } else {
          result[key] = { ...datasGeojson[key], features: [] };
        }
      }

      setResultGeojsons(result);
    } else {
      let result = {};

      for (const key of Object.keys(datas)) {
        if (
          !datas[key].tiles &&
          (!datas[key].displayOn || datas[key].displayOn?.includes(location.pathname.replace("/", "")))
        ) {
          result[key] = datasGeojson[key];
        }
      }

      setResultGeojsons(result);
    }

    // eslint-disable-next-line
  }, [selectedFocus, datasGeojson]);

  return { datasGeojson: resultGeojsons, datasInfos: resultInfos, datas };
};

export default useSelectedFocus;
