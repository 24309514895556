import L from "leaflet";
import { translate } from "../services/tools";
import history from "../history";

const { REACT_APP_PROJECT } = process.env;

function icon(name, size = 22) {
  return new L.Icon({
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    popupAnchor: [0, -size / 2],
    iconUrl: `/assets/images/${name}.svg`,
  });
}

function existsAndYes(value) {
  return value?.toLowerCase() === "oui";
}

export default class InteractiveMapObjectInterface {
  constructor(feature, file) {
    switch (REACT_APP_PROJECT) {
      case "bretagne-canaux":
        feature.icon = icon(file);

        feature.geometry =
          feature.geometry.type === "MultiPoint"
            ? {
                type: "Point",
                coordinates: feature.geometry.coordinates[0],
              }
            : feature.geometry;

        switch (file) {
          case "petites-boucles":
            const pathname = history.location.pathname.replace("/", "");

            feature.icon = icon(`${file}-${pathname}`);
            break;
          case "entree-fluvio-maritimes":
            feature.properties.color = "#06c";
            break;
          case "aeroport":
            feature.properties.name = `${translate(file)} de ${
              feature.properties.name.includes(" de ")
                ? feature.properties.name.split(" de ")[1]
                : feature.properties.name
            }`;
            break;
          case "arret-car-bretagne":
            feature.properties.name = `${translate(file)} ${feature.properties.nom}`;
            break;
          case "borne":
            const type =
              existsAndYes(feature.properties?.eau) &&
              existsAndYes(feature.properties?.elec) &&
              existsAndYes(feature.properties?.eu)
                ? "borne-eau-elec-eu"
                : existsAndYes(feature.properties?.eau) && existsAndYes(feature.properties?.elec)
                ? "borne-eau-elec"
                : existsAndYes(feature.properties?.eau) && existsAndYes(feature.properties?.eu)
                ? "borne-eau-eu"
                : existsAndYes(feature.properties?.elec) && existsAndYes(feature.properties?.eu)
                ? "borne-elec-eu"
                : existsAndYes(feature.properties?.elec)
                ? "borne-elec"
                : existsAndYes(feature.properties?.eu)
                ? "borne-eu"
                : "borne-eau";

            feature.properties.type = type;
            feature.properties.name = translate(type);
            feature.icon = icon(type);

            if (feature.properties.id_plaque) {
              feature.properties.report =
                "<div class='lc-report'>Si un équipement est défectueux, merci de le signaler en mentionnant son numéro d'identification <a href='https://canaux.bretagne.bzh/signaler' target='_blank' class='lc-button'>Signaler</a></div>";
            }

            break;
          case "cale-mise-a-leau":
            feature.properties.name = translate(file);
            break;
          case "ecluse":
            const { num_ecluse, nom } = feature.properties;

            feature.properties.title = `${translate(file)} ${num_ecluse}`;
            feature.properties.name = `${translate(file)} ${nom}`;

            if (feature.properties.pk) {
              feature.properties.pk = String(feature.properties.pk).split(" km")[0] + " km";
            }

            break;
          case "gare-bretagne":
            feature.properties.name = `${translate(file)} ${feature.properties.nom}`;
            break;
          case "mobilier":
            const { type_mob } = feature.properties;

            if (feature.properties.id_plaque) {
              feature.properties.report =
                "<div class='lc-report'>Si un équipement est défectueux, merci de le signaler en mentionnant son numéro d'identification <a href='https://canaux.bretagne.bzh/signaler' target='_blank' class='lc-button'>Signaler</a></div>";
            }

            feature.properties.type = type_mob.toLowerCase();
            feature.properties.name = type_mob;
            break;
          case "office-tourisme":
            feature.properties.name = feature.properties.nom;
            break;
          case "parking":
            feature.properties.name = translate(file);
            break;
          case "petite-cite-de-caractere":
            feature.properties.name = `${feature.properties.nom}<br/>${translate(file)}`;
            break;
          case "point-deau":
            feature.properties.name = translate(file);
            break;
          case "quai-ponton":
            feature.properties.name = translate(file);
            break;

          case "toilettes-douche":
            const { wc, douche } = feature.properties;

            if (feature.properties.id_plaque) {
              feature.properties.report =
                "<div class='lc-report'>Si un équipement est défectueux, merci de le signaler en mentionnant son numéro d'identification <a href='https://canaux.bretagne.bzh/signaler' target='_blank' class='lc-button'>Signaler</a></div>";
            }

            feature.properties.name =
              wc?.toLowerCase() === "oui" && douche?.toLowerCase() === "oui"
                ? "Sanitaires / Douche"
                : wc?.toLowerCase() === "oui"
                ? "Sanitaires"
                : douche?.toLowerCase() === "oui"
                ? "Douche"
                : "'wc'/'douche' non renseigné";
            feature.icon = icon(
              wc?.toLowerCase() === "oui" && douche?.toLowerCase() === "oui"
                ? "toilettes-douche"
                : wc?.toLowerCase() === "oui"
                ? "toilettes"
                : douche?.toLowerCase() === "oui"
                ? "douche"
                : "toilettes"
            );
            break;
          case "ville-art-histoire":
            feature.properties.name = `${feature.properties.name.split("<br/>")[0]}<br/>${translate(file)}`;
            break;

          default:
            break;
        }

        return feature;
      default:
        break;
    }
  }
}
