import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PrintItineraryRoadmap from "../print/PrintItineraryRoadmap";
import PrintItineraryMap from "../print/PrintItineraryMap";
import PrintItineraryMapWalk from "../print/PrintItineraryMapWalk";
import history from "../history";
import { getURLSearchParams } from "../services/tools";

const Document = styled.div`
  /* padding: 30px; */
`;

const Print = (props) => {
  const printState = useSelector((state) => state.app.print);
  const { datas, pages } = printState;
  const params = getURLSearchParams(history.location);
  const [printDone, setPrintDone] = useState(false);

  setTimeout(() => {
    setPrintDone(true);
  }, 2000);

  return (
    <Document>
      {pages.map((page, index) => {
        switch (page.type) {
          case "itinerary-roadmap":
            return (
              <PrintItineraryRoadmap key={`print-page-${index}`} page={page} datas={datas} modes={params?.modes} />
            );
          case "itinerary-map":
            return <PrintItineraryMap key={`print-page-${index}`} page={page} datas={datas} />;
          case "itinerary-map-walk":
            return <PrintItineraryMapWalk key={`print-page-${index}`} page={page} datas={datas} />;
          default:
            return <></>;
        }
      })}
      {printDone && <div id="print-done"></div>}
    </Document>
  );
};

export default Print;
