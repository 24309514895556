import Tippy from "@tippy.js/react";
import axios from "../middlewares/axios";
import L from "leaflet";
import { Polyline } from "react-leaflet";
import { luminance } from "luminance-js";
import React from "react";
import { GeoJSON, Marker, Popup, Tooltip } from "react-leaflet";
import { UILine } from "../components/styled/UILine";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { actionSetOpenedCollapse, actionSetPlaceClicked } from "../actions/board";
import {
  actionMarkerClick,
  actionSetBikePaths,
  actionSetCluster,
  actionSetEntranceMapMarkers,
  actionSetEntrancePopup,
  actionSetHeavyLines,
  actionSetCustomLines,
  actionSetMapBikes,
  actionSetCustomMarkerEvent,
  actionSetLinesToDisplay,
  actionSetAllLinesSelected,
  actionSetCustomMarkers,
} from "../actions/map";
import {
  actionBuildMapPlaces,
  actionBuildTransportPlaces,
  actionOnLineSelected,
  actionOpenMarker,
  actionOutMarker,
  actionOverMarker,
} from "../actions/withRedux";
import history from "../history";
import BikeInterface from "../interfaces/BikeInterface";
import { appStore } from "../store";
import { groupLinesByMode } from "../utils/leaflet/tools";
import { updateMapEvents, removeMapEvents, fitBounds } from "../utils/leaflet/map";
import {
  buildPlaceIconClassName,
  clickOnPlaceInList,
  envVarToBool,
  flattenObject,
  getLine,
  getRef,
  getURLSearchParams,
  goToRouteCalculation,
  isNotToClusterised,
  mostImportantGroup,
  unique,
  updatePopupPosition,
  assetsPath,
  handleKeyPress,
  addGetParam,
  translate,
  isActiveModule,
} from "./tools";
import { message } from "./message";
import { actionSetStreetviewPosition } from "../actions/app";
import UIPoiContent from "../components/styled/UIPoiContent";

const {
  REACT_APP_LINES_MAIN_TYPE,
  REACT_APP_LINES_TYPE_EXCEPTIONS,
  REACT_APP_SHOW_PMR,
  REACT_APP_SHOW_ADDITIONAL_STOP_TOOL,
  REACT_APP_CONNECTIONS_TEXT,
  REACT_APP_PROXIMITY_LINES_AT_STOP,
  REACT_APP_GO_TO_RC_URL,
  REACT_APP_STREETVIEW,
  REACT_APP_DEFAULT_LINES_WEIGHT,
} = process.env;

export const buildBikePaths = async (files) => {
  const requests = [];

  for (const file of files) {
    const options = {
      color: file.style.color,
      opacity: 1,
      weight: file.style.size,
      dashArray: file.style.dashArray,
      lineJoin: "round",
    };

    requests.push(
      axios.get(`/api/file?name=${file.id}&folder=${"map/bike"}&ext=geojson`).then((response) => {
        return <GeoJSON interactive={false} key={file.id} data={response.data} style={options} />;
      })
    );
  }

  Promise.all(requests).then((paths) => {
    appStore.dispatch(actionSetBikePaths(paths));
  });
};

export const buildEntranceMap = (geojson, map) => {
  if (!geojson || !Object.keys(geojson).length) {
    message({ error: "geojson_entrance_map_not_found" });
    return;
  }

  const config = appStore.getState()?.app?.config;
  const markers = [];

  if (map.mapReference.current) {
    map = map.mapReference.current.leafletElement;
  }

  for (const feature of geojson.features) {
    const isTerminus = feature.properties.type === "terminus";
    const type = feature.properties.type;

    // TEMPORARY UNTIL TERMINUS ARE FINISHED !
    if (isTerminus) {
      continue;
    }
    // END TEMPORARY

    // Don't display "jalon" if no heavyLines are drawn
    if (config.heavyLines === false) {
      if (type === "jalon") {
        continue;
      }
    }

    if (feature.geometry.type.includes("String")) {
      markers.push(
        <GeoJSON
          key={Math.random()}
          data={feature}
          style={{
            opacity: 0,
            weight: 18,
          }}
          onMouseMove={(e) => {
            const storedPopup = appStore.getState().map.entrancePopup;
            const popup = e.target.getPopup();

            if (!storedPopup || storedPopup._leaflet_id !== popup._leaflet_id) {
              popup.setLatLng(e.latlng).openOn(map);
            }
          }}
          onMouseOut={(e) => {
            const storedPopup = appStore.getState().map.entrancePopup;
            const popup = e.target.getPopup();

            if (!storedPopup || storedPopup._leaflet_id !== popup._leaflet_id) {
              e.target.closePopup();
            }
          }}
          onClick={(e) => {
            map.eachLayer((layer) => layer.closePopup());

            const popup = e.target.getPopup();

            appStore.dispatch(actionSetEntrancePopup(popup));
            popup.setLatLng(e.latlng).openOn(map);
          }}
        >
          <Popup
            className={"lc-popup-leaflet"}
            closeButton={false}
            autoClose={false}
            autoPan={false}
            onClose={() => appStore.dispatch(actionSetEntrancePopup(null))}
          >
            {buildPopup(
              appStore.getState(),
              {
                lines: feature.properties.desserte.split(";").map((line) => ({
                  code: line.split("_")[0],
                  network: line.split("_")[1],
                })),
              },
              true
            )}
          </Popup>
        </GeoJSON>
      );
      markers.push(
        <GeoJSON
          key={Math.random()}
          style={{
            color: "#888",
          }}
          data={feature}
          interactive={false}
        />
      );
    } else {
      markers.push(
        <Marker
          key={(isTerminus ? "terminus-" : "jalon-") + Math.random()}
          interactive={false}
          position={[feature.geometry.coordinates[1], feature.geometry.coordinates[0]]}
          icon={L.icon({
            iconUrl: assetsPath("/assets/images/lines/entrance/") + feature.properties.image + ".svg",
            iconSize: feature.properties.size,
            iconAnchor: feature.properties.anchor,
          })}
          zIndexOffset={isTerminus ? 100 : 50}
          zoom={feature.properties.zoom}
        />
      );
    }
  }

  setTimeout(() => appStore.dispatch(actionSetEntranceMapMarkers(markers)));
};

/*
 * Build all lines on map
 * @param geojson
 * @param map
 * @param selected = {type: "", id:"""}
 * type : dl|code_reg|cat|code
 * id of type
 */
export const buildAllLines = (map, selected, newWeight = false) => {
  const geojson = appStore.getState().map.allLinesGeojson;
  const geojsonIntersec = appStore.getState().map.allLinesIntersecGeojson;
  const params = getURLSearchParams(history.location);

  // on ne redessine pas si on a toujours le même selected
  if (
    selected !== undefined &&
    appStore.getState().map.allLinesSelected !== undefined &&
    selected?.type === appStore.getState().map.allLinesSelected?.type &&
    selected?.id === appStore.getState().map.allLinesSelected?.id &&
    !newWeight
  ) {
    return;
  }

  if (!selected && appStore.getState().map.allLinesSelected) {
    selected = appStore.getState().map.allLinesSelected;
  }

  if (!geojson || !Object.keys(geojson).length) {
    message({ error: "geojson_all_lines_not_found" });
    return;
  }

  const getLineColor = (line) => {
    const allLinesColorType = appStore.getState().map.allLinesColorType;
    const ddls = appStore.getState().app.ddls;

    if (selected && selected.type) {
      if (line !== undefined) {
        if (selected.type === "indicator") {
          let color = "";

          selected.steps.forEach((step) => {
            if (step.ids.includes(line.id)) {
              color = step.color;
            }
          });
          return color;
        } else {
          if (
            (params.cat === undefined && String(line[selected.type]) === String(selected.id)) ||
            (params.cat &&
              String(params.cat) === String(line.cat) &&
              String(line[selected.type]) === String(selected.id))
          ) {
            if (allLinesColorType === "ddl") {
              return ddls.find((dl) => dl.code === line.dl)?.color;
            } else {
              return selected.color ? selected.color : "#" + line.color;
            }
          } else {
            return "#A0A0A0";
          }
        }
      } else {
        if (allLinesColorType === "ddl") {
          return ddls.find((dl) => dl.code === line.dl)?.color;
        } else {
          return "#" + line.color;
        }
      }
    } else {
      if (allLinesColorType === "ddl") {
        return ddls.find((dl) => dl.code === line.dl)?.color;
      } else {
        return "#" + line.color;
      }
    }
  };

  function getWeight(weight, map) {
    const zoom = map.mapReference.current.leafletElement.getZoom();

    if (zoom === 8) {
      return weight / 3;
    } else if (zoom === 9) {
      return weight / 2;
    } else {
      return weight;
    }
  }

  if (map.mapReference.current) {
    // map = map.mapReference.current.leafletElement;
    if (selected && selected.type && selected.type === "id" && params.line === undefined) {
      updateMapEvents(map, "onClick", (event) => {
        appStore.dispatch(actionSetAllLinesSelected({ type: "", id: null }));
      });
    } else {
      removeMapEvents(map);
    }

    // on reculcacule la weight des tracés en fonction du zoom level
    updateMapEvents(map, "onZoomend", (event) => {
      buildAllLines(map, selected, true);
    });
  }

  const lines = [];

  geojson.features
    .map((line) => {
      const findLine = appStore.getState().app.lines.find((l) => String(l.id) === String(line.properties["ID"]));

      if (findLine) {
        let color = getLineColor(findLine);
        let weight = getWeight(findLine.weight, map);

        return { ...line, color: color, weight: weight, findLine: findLine };
      } else {
        return { ...line, findLine: findLine };
      }
    })
    .sort((l1, l2) => {
      // on tri les lignes pour que celles qui sont grises soient en dessous au moment du rendu
      if (l1.color === "#A0A0A0") {
        return -1;
      } else if (l2.color === "#A0A0A0") {
        return 1;
      } else {
        return 0;
      }
    })
    .forEach((line, index) => {
      const findLine = line.findLine;

      if (findLine) {
        lines.push(
          <Polyline
            key={"pl-" + index}
            positions={line.geometry.coordinates.map((coord) => [coord[1], coord[0]])}
            color={line.color}
            weight={line.weight}
            interactive={true}
            onClick={(e) => {
              history.push({
                pathname: "/lines-regions/services",
                search: "?line=" + findLine.id,
              });
            }}
          >
            <Tooltip direction="top" sticky={true}>
              <div key={"popup-select-line-" + findLine.id}>
                <UILine image={false} line={findLine} />
              </div>
            </Tooltip>
          </Polyline>
        );
      }
    });

  if (geojsonIntersec && geojsonIntersec.features && geojsonIntersec.features.length > 0) {
    geojsonIntersec.features.forEach((intersec, index) => {
      const weights = intersec.properties.lines.split(";").map((id) => {
        return appStore.getState().app.lines.find((l) => String(l.id) === String(id))?.weight;
      });

      let weight = getWeight(Math.max(...weights), map);

      const geojsonContent = (
        <div className="lc-intersection-content">
          {intersec.properties.lines.split(";").map((id) => {
            const findLine = appStore.getState().app.lines.find((l) => String(id) === String(l.id));

            if (findLine) {
              return (
                <div
                  key={"popup-select-line-" + findLine.id}
                  role="button"
                  tabIndex="0"
                  onClick={(e) => {
                    history.push({
                      pathname: "/lines-regions/services",
                      search: "?line=" + findLine.id,
                    });
                  }}
                  onKeyPress={(e) =>
                    handleKeyPress(e, () => {
                      history.push({
                        pathname: "/lines-regions/services",
                        search: "?line=" + findLine.id,
                      });
                    })
                  }
                >
                  <UILine image={false} line={findLine} />
                </div>
              );
            } else {
              return "";
            }
          })}
        </div>
      );

      lines.push(
        <GeoJSON
          key={"intersec-" + index}
          data={intersec}
          style={() => ({
            color: "#000000",
            opacity: 0,
            weight: weight,
            interactive: true,
          })}
        >
          <Popup
            className="lc-intersections-popup"
            closeButton={false}
            autoClose={false}
            autoPan={false}
            keepInView={true}
            direction="right"
          >
            {geojsonContent}
          </Popup>
          <Tooltip direction="top" sticky={true}>
            {geojsonContent}
          </Tooltip>
        </GeoJSON>
      );
    });
  }

  setTimeout(() => {
    // on centre sur la selection de ligne
    // seulement quand on a des lignes à mettre en avant (color !== "#A0A0A0")
    const linesZoomOn = lines.filter((l) => l.props.color !== "#A0A0A0" && !l.key.includes("intersec"));
    const linesCount = lines.filter((l) => !l.key.includes("intersec"));

    if (linesZoomOn.length !== linesCount.length && !newWeight) {
      fitBounds(map, linesZoomOn);
    }
  }, 500);

  setTimeout(() => {
    appStore.dispatch(actionSetLinesToDisplay(lines)); // appStore.getState().map.reactLines
  });
};

/**
 * Build & render heavy lines
 * @param state
 */
export const buildHeavyLines = (state, zoom) => {
  if (state.map.heavyLines) {
    return;
  }

  if (state?.app?.config?.heavyLines === false) {
    return;
  }

  const { heavyIds, lines, hash, map } = state.app;
  const { pathname } = history.location;

  if (!pathname.includes("route-calculation")) {
    const requests = [];

    for (const id of heavyIds) {
      const data = getLine(lines, {
        id,
        direction_id: "f",
      });

      if (data.code) {
        // TODO recode displayLinePath
        requests.push(buildLinePath(data, hash));
      }
    }

    Promise.all(requests).then((polylines) => {
      appStore.dispatch(actionSetHeavyLines(polylines));

      if (!zoom) {
        fitBounds(map, polylines);
      }
    });
  }
};

export function buildCustomLines(state, customMapLines) {
  const { lines, hash } = state.app;
  const requests = [];

  for (const customLine of customMapLines) {
    const data = getLine(lines, {
      id: customLine.id ? customLine.id : customLine,
      direction_id: "f",
    });

    if (data.code) {
      // TODO recode displayLinePath
      requests.push(buildLinePath(data, hash));
    } else {
      message({ error: "custom_line_not_found", id: customLine, message: "Custom line is not found in line list" });
    }
  }

  Promise.all(requests).then((polylines) => {
    appStore.dispatch(actionSetCustomLines(polylines));
  });
}

export const buildLinePath = (data, hash) => {
  // Force direction_id to 'f' by default if not exists
  if (!data.direction_id) {
    data.direction_id = "f";
  }

  const folder = data.type ? (+data.type !== 4 ? "routes/future/lines" : "routes/current/lines") : "routes";

  const name = data.type
    ? `${data.code}~${hash}`
    : `${encodeURIComponent(data.code)}_${data.network}_${data.direction_id}~${hash}`;

  return axios
    .get(`/api/file?folder=${folder}&ext=geojson&name=${name}`)
    .then((response) => {
      const geojson = response.data.length === 0 ? null : response.data;

      const options = {
        color: "#" + data.color,
        opacity: 1,
        weight: REACT_APP_DEFAULT_LINES_WEIGHT ? REACT_APP_DEFAULT_LINES_WEIGHT : 6,
        zIndex: 7,
      };

      if (geojson) {
        if (data.tad && data.tad.zone) {
          // Navitia can't integrate Polygon type... so MultiLineString became a Polygon :D
          geojson.features[0].geometry.type = "Polygon";
          options.weight = 2;
          options.fillColor = "#" + data.color;
          options.fillOpacity = 0.2;
        }

        if (data.dashArray !== undefined) {
          options.dashArray = data.dashArray;
        }

        if (data.weight !== undefined) {
          options.weight = data.weight;
        }

        return (
          <GeoJSON
            interactive={false}
            key={`${data.code}_${data.network}_${data.direction_id}`}
            data={geojson}
            style={options}
          />
        );
      } else {
        message({
          error: "geojson_line_not_found",
          id: data.code,
          message: "Line has no geojson",
        });
      }
    })
    .catch((e) => {
      const error = e.response && e.response.data ? e.response.data.id : e;

      console.warn(error);
    });
};

export const buildCustomMarkers = (customMarkers) => {
  const markers = [];

  try {
    for (const m of customMarkers) {
      if (m.id === undefined) {
        console.warn("Custom marker has no id");
        message({ error: "custom_marker_no_id", message: "Custom marker must have an id" });
      } else {
        const splitName = m.title?.split(/[()]/);
        const hasSubtitle = splitName?.length > 1;

        markers.push(
          <Marker
            key={"customMarker-" + m.id}
            name={m.title}
            position={m.position}
            icon={L.icon({
              iconUrl: m.icon.url,
              iconSize: m.icon.size || [26, 26],
              iconAnchor: m.icon.anchor || [13, 13],
              className: m.icon.className,
            })}
            zIndexOffset={m.zIndexOffset}
            onMouseOver={(e) => {
              if (m.title) {
                if (!e.target.isPopupOpen()) {
                  e.target.openPopup();
                  setTimeout(() => {
                    updatePopupPosition(e.target);
                  });
                  appStore.dispatch(actionSetCustomMarkerEvent(m.id, "mouseover"));
                }
              }
            }}
            onMouseOut={(e) => {
              if (m.title) {
                if (
                  appStore.getState().map.customMarkers.find((mf) => mf.key === "customMarker-" + m.id)?.lastEvent ===
                  "mouseover"
                ) {
                  e.target.closePopup();
                  appStore.dispatch(actionSetCustomMarkerEvent(m.id, ""));
                }
              }
            }}
            onClick={(e) => {
              if (m.title) {
                if (
                  appStore.getState().map.customMarkers.find((mf) => mf.key === "customMarker-" + m.id)?.lastEvent ===
                  "mouseover"
                ) {
                  message({ clicked: "custom-marker", id: m.id });
                  appStore.dispatch(actionSetCustomMarkerEvent(m.id, "click"));
                  setTimeout(() => {
                    e.target.openPopup();
                  });
                } else {
                  appStore.dispatch(actionSetCustomMarkerEvent(m.id, ""));
                }
              }
            }}
          >
            {m.title && (
              <Popup
                className={"lc-popup-leaflet"}
                closeButton={false}
                autoClose={false}
                autoPan={false}
                keepInView={true}
              >
                <div className="lc-infobox">
                  <div className="lc-infobox-title">
                    <span>
                      {splitName[0]}
                      {hasSubtitle && <em>({m.name.split(/[()]/)[1]})</em>}
                    </span>
                  </div>
                  <div className="lc-infobox-content" dangerouslySetInnerHTML={{ __html: m.description }} />
                </div>
              </Popup>
            )}
          </Marker>
        );
      }
    }
  } catch (e) {
    console.warn("Something went wrong while building custom markers");
    throw e;
  }

  return markers;
};

export const buildStreeviewMarker = () => {
  const coords = appStore.getState().app.streetviewPosition;

  return (
    <Marker
      id="streetview-marker"
      key="streetview-marker"
      draggable
      onDragend={(event) => {
        appStore.dispatch(actionSetStreetviewPosition(event.target.getLatLng()));
      }}
      options={{ zIndex: 999 }}
      icon={
        new L.icon({
          iconUrl: assetsPath("/assets/images/streetview.svg"),
          iconSize: [30, 30],
          iconAnchor: [15, 15],
        })
      }
      position={[coords.lat, coords.lng]}
      onMouseOver={(e) => {
        if (!e.target.isPopupOpen()) {
          e.target.openPopup();
          setTimeout(() => {
            updatePopupPosition(e.target);
          });
          appStore.dispatch(actionSetCustomMarkerEvent("streetview-marker", "mouseover"));
        }
      }}
      onClick={(e) => {
        appStore.dispatch(
          actionSetCustomMarkers(appStore.getState().map.customMarkers.filter((m) => m.key !== "streetview-marker"))
        );
      }}
    >
      <Popup
        className={"lc-popup-leaflet lc-popup-streetview"}
        closeButton={false}
        autoClose={false}
        autoPan={false}
        keepInView={true}
      >
        <div className="lc-infobox">
          <span className="lc-infobox-title">
            {translate("google-streetview")}
            <div
              className="lc-infobox-title-tools"
              onClick={() =>
                appStore.dispatch(
                  actionSetCustomMarkers(
                    appStore.getState().map.customMarkers.filter((m) => m.key !== "streetview-marker")
                  )
                )
              }
            >
              <div className="lc-close" />
            </div>
          </span>
          <div className="lc-infobox-content lc-streetview">
            <iframe
              title="streetview"
              style={{ border: 0 }}
              loading="lazy"
              height="350px"
              width="500px"
              disableDefaultUI="true"
              src={`https://www.google.com/maps/embed/v1/streetview?key=${REACT_APP_STREETVIEW}&location=${
                appStore.getState().app.streetviewPosition.lat
              },${appStore.getState().app.streetviewPosition.lng}`}
            ></iframe>
          </div>
        </div>
      </Popup>
    </Marker>
  );
};

export const buildMapBikes = (state, bikes) => {
  const markers = [];

  for (const bike of bikes) {
    markers.push(
      buildMarker(state, bike, {
        icon: L.icon({
          iconUrl: assetsPath("/assets/images/menu/velo.svg"),
          className: buildPlaceIconClassName(bike),
        }),
        bike,
        zIndexOffset: 200,
      })
    );
  }

  appStore.dispatch(actionSetMapBikes(markers));
};

/**
 * Build a marker component from the given data
 * @param state
 * @param data
 * @param options
 * @returns Marker
 */
export const buildMarker = (state, data, options) => {
  let currentLine = state?.app?.component?.state?.currentLine;
  const terminusStyle = state?.app?.terminusStyle;
  const lines = state?.app?.lines;
  const params = getURLSearchParams(history.location);

  if (!currentLine && lines && params.current) {
    currentLine = getLine(state.app.lines, {
      id: params.current.substring(0, params.current.lastIndexOf("_")),
    });
  }

  return (
    <Marker
      key={data.id}
      ref={(ref) => {
        data.ref = ref;
        // appStore.dispatch(actionAddReduxRef(ref))
      }}
      name={data.name}
      onMouseOver={() => {
        appStore.dispatch(actionOverMarker(data));
      }}
      onMouseOut={(e) => {
        const target = e.originalEvent.target;

        if (!target.classList.contains("leaflet-tooltip")) {
          setTimeout(() => appStore.dispatch(actionOutMarker(data)));
        }
      }}
      onClick={() => {
        message({ clicked: "marker", id: data.id });
        appStore.dispatch(actionOpenMarker(data));
      }}
      position={[+data.coord.lat, +data.coord.lon]}
      {...options}
    >
      {options.terminus && data.terminus && (
        <Tooltip
          key={"terminus_" + data.id}
          direction={"right"}
          onClick={() => appStore.dispatch(actionOpenMarker(data, true))}
          className={"lc-tooltip-leaflet-terminus"}
          opacity={1}
          interactive
          permanent
        >
          {["imageAndCityName"].includes(terminusStyle) && currentLine ? (
            <div className={"lc-tooltip-leaflet-terminus-with-image"}>
              <UILine line={currentLine} image={REACT_APP_LINES_MAIN_TYPE.includes("image")} />
              <div className="lc-tooltip-leaflet-terminus-title">
                {data.town && <div className="lc-terminus-commune">{data.town}</div>}
                <div className={"lc-terminus-name" + (data.town ? " has-town" : "")}>{data.name}</div>
              </div>
            </div>
          ) : (
            data.name
          )}
        </Tooltip>
      )}
      <Popup
        className={"lc-popup-leaflet"}
        closeButton={false}
        autoClose={false}
        autoPan={false}
        onClose={() => {
          const needRequest = [
            "poi_type:amenity:park_ride",
            "poi_type:amenity:citiz",
            "poi_type:amenity:bicycle_rental",
            "poi_type:vcub",
            "poi_type:p+r",
          ];

          if (
            data.stand &&
            !isActiveModule("thematic") &&
            !needRequest.includes(data.cat_id) &&
            state?.app?.component?.state?.pois
          ) {
            // ! Avoid loop on "covoiturage" for Artis. data.stand must be dynamic
            appStore.dispatch(actionBuildTransportPlaces(state.app.component.state.pois));
          }
        }}
      >
        {buildPopup(state, data)}
      </Popup>
    </Marker>
  );
};

/**
 * Build a marker component from the given data
 * @param data
 * @param options
 * @returns Marker
 */
export const buildCustomMarker = (data, options) => {
  const splitName = data.name.split(/[()]/);
  const hasSubtitle = splitName.length > 1;

  return (
    <Marker
      key={data.id}
      ref={(ref) => {
        data.ref = ref;
      }}
      position={[+data.coord.lat, +data.coord.lon]}
      onClick={(e) => {
        message({
          clicked: options.postMessageEventName ? options.postMessageEventName : "custom-marker",
          id: data.id,
        });
      }}
      {...options}
    >
      {data.terminus && (
        <Tooltip
          key={"terminus_" + data.id}
          direction={"right"}
          className={"lc-tooltip-leaflet-terminus"}
          opacity={1}
          permanent
        >
          {data.name}
        </Tooltip>
      )}
      {data.content && (
        <Popup
          className={"lc-popup-leaflet lc-custom-popup-leaflet"}
          closeButton={false}
          autoClose={false}
          autoPan={false}
        >
          <div className={"lc-infobox"}>
            <div className="lc-infobox-title">
              <span>
                {splitName[0]}
                {hasSubtitle && <em>({data.name.split(/[()]/)[1]})</em>}
              </span>
              {data.pmr && (
                <div className="lc-infobox-title-tools lc-with-pmr">
                  <div className="lc-is-pmr"></div>
                </div>
              )}
            </div>
            <div className="lc-infobox-content">{data.content}</div>
          </div>
        </Popup>
      )}
    </Marker>
  );
};

/**
 * Build all places markers
 * @param state
 * @param places
 * @returns {Array}
 */
export const buildPlaces = (state, places) => {
  if (!places) {
    return;
  }

  const flattenPlaces = Array.isArray(places) ? places : flattenObject(places);
  const markers = [];
  const { markerMode } = state.map;

  for (const place of flattenPlaces) {
    if (place.coord) {
      markers.push(
        buildMarker(state, place, {
          icon: place.divIcon
            ? L.divIcon({
                className: `lc-place-divicon lc-${place.color} lc-place-${place.cat_id
                  .replace("poi_type:", "")
                  .replace("+", "")
                  .toLowerCase()}`,
                html:
                  markerMode === "default"
                    ? `<img src="${assetsPath(`/assets/images/places/${place.code}.svg`)}"/>${
                        place.value !== null && place.color !== "closed"
                          ? `<span class="${place.value > 99 ? "lc-long-value" : ""}">${place.value}</span>`
                          : ""
                      }`
                    : `<div class="lc-small-circle-marker"/>`,
                iconSize: [0, 0],
              })
            : L.icon({
                iconUrl: place.code
                  ? assetsPath(`/assets/images/places/${place.code}.svg`)
                  : assetsPath("/assets/images/stop_point.svg"),
                className: buildPlaceIconClassName(place.cat_id),
              }),
          place,
          zIndexOffset: isActiveModule("thematic") ? 200 : 40,
        })
      );
    }
  }

  return markers;
};

/**
 * Triggered when a line is selected.
 * The behavior can be different on each modules
 * @param state
 * @param line
 * @param data
 */
export const onLineSelected = (state, line, data) => {
  // If we have no routes on our line, just don't do anything
  if (line?.routes?.length === 0) {
    return;
  }

  const { pathname } = history.location;
  const params = getURLSearchParams(history.location);

  if (isActiveModule("around")) {
    const part =
      `line=${line.id}_${line.direction_id || "f"}` +
      (data ? `&stop=${data.id}` : "") +
      (params.date ? `&date=${params.date}` : "");

    // TODO ! Remove insee from URL and use from
    if (params && (params.from || params.insee || params.place)) {
      history.push({
        pathname,
        search:
          (params.from ? "?from=" + params.from : params.place ? "?place=" + params.place : "?insee=" + params.insee) +
          "&" +
          part,
      });
    } else {
      history.push({
        pathname,
        search: "?" + part,
      });
    }
  } else if (isActiveModule("multimobilities")) {
    if (!data || !data.id) {
      const searchParam = addGetParam(params, { current: `${line.id}_${line.direction_id || "f"}` });

      history.push({
        pathname,
        search: searchParam,
      });
    } else {
      const searchParam = addGetParam(params, { current: `${line.id}_${line.direction_id || "f"}`, stop: data.id });

      history.push({
        pathname,
        search: searchParam,
      });
    }
  } else {
    if (!data || !data.id) {
      // ! Do not comment this : needed to switch direction on Lines component
      const searchParam = addGetParam(params, { current: `${line.id}_${line.direction_id || "f"}` });

      history.push({
        pathname: "/lines",
        search: searchParam,
      });
    } else {
      const searchParam = addGetParam(params, { current: `${line.id}_${line.direction_id || "f"}`, stop: data.id });

      history.push({
        pathname: "/lines",
        search: searchParam,
      });
    }
  }
};

/**
 * Triggered when user mouse leaves a marker
 * Retrieve the current openedMarker & test if its not equal to the given one.
 * If false, close the popup of the given marker
 * @param state
 * @param data
 */
export const onMarkerMouseOut = (state, data) => {
  const { openedMarker, reduxMarkers } = state.map;

  if (!openedMarker || (openedMarker && openedMarker.id !== data.id)) {
    const ref = getRef(data, reduxMarkers);

    if (ref) {
      // Force close popup
      ref.leafletElement.closePopup();
    }
  }
};

/**
 * Triggered when user mouse enter on a marker
 * Retrieve the current openedMarker & test if its not equal to the given one or, if openedMarker is defined, if its popup is closed.
 * If one is true, open the popup of the given marker
 * @param state
 * @param data
 */
export const onMarkerMouseOver = (state, data) => {
  const { openedMarker, reduxMarkers, customMarkers } = state.map;

  if (openedMarker !== data || (openedMarker && openedMarker.ref && !openedMarker.ref.leafletElement.isPopupOpen())) {
    const ref = getRef(data, reduxMarkers.length > 0 ? reduxMarkers : customMarkers);

    if (ref) {
      // Open popup (delays it a bit to avoid position problem)
      const element = ref.leafletElement;

      setTimeout(() => {
        element.openPopup();
        updatePopupPosition(element, data);
      });
    }
  }
};

/**
 * Open the popup of the given marker
 * @param state
 * @param data
 */
export const onOpenMarker = (state, data) => {
  const { pathname, search } = history.location;
  const { component, linesModes } = state.app;
  const { openedCollapse, thematicPlaces } = state.board;
  const { cluster, mapPlaces } = state.map;
  const { pois, tab } = component?.state ? component.state : { pois: [], tab: 0 };
  const params = getURLSearchParams(history.location);

  const needRequest = [
    "poi_type:amenity:bicycle_rental",
    "poi_type:amenity:bicycle_parking",
    "poi_type:amenity:car_rental",
    "poi_type:amenity:citiz",
    "poi_type:amenity:parking",
    "poi_type:stations",
  ];

  !pathname.includes("/lines") &&
    data.cat_id &&
    openedCollapse !== data.cat_id &&
    appStore.dispatch(actionSetOpenedCollapse(data.cat_id));

  if (state.board.thematicPlaces && !(data instanceof BikeInterface) && !needRequest.includes(data.cat_id)) {
    const searchParam = addGetParam(params, { place: data.id });

    history.push({
      pathname,
      search: searchParam,
    });

    appStore.dispatch(actionMarkerClick(data));
  } else if (!pois || pois.length === 0) {
    if (needRequest.includes(data.cat_id) && pathname.includes("/citiz")) {
      if (search !== "?place=" + data.id) {
        const searchParam = addGetParam(params, { place: data.id });

        history.push({
          ...history.location,
          search: searchParam,
        });
      }
    } else if (data instanceof BikeInterface && pathname.includes("/bike")) {
      if (search !== "?id=" + data.id) {
        const searchParam = addGetParam(params, { id: data.id });

        history.push({
          ...history.location,
          search: searchParam,
        });
      }
    } else {
      const places = mapPlaces.map((place) => place.props.place);
      const place = places.find((place) => place.id === data.id);

      if (place && needRequest.includes(place.cat_id)) {
        if (place.cat_id !== "poi_type:stations") {
          const type = place.cat_id.includes("bicycle_rental")
            ? "bss"
            : place.cat_id.includes("bicycle_parking")
            ? "bike_parking"
            : place.cat_id.includes("car_rental")
            ? "car_rental"
            : place.cat_id.includes("citiz")
            ? "citiz"
            : "parking";

          axios
            .get(`/api/availability?type=${type}&id=${place.id}`)
            .then((result) => {
              place.stand = result.data;
              appStore.dispatch(actionBuildMapPlaces(places));

              if (
                ["poi_type:amenity:car_rental", "poi_type:amenity:citiz", "poi_type:amenity:parking"].includes(
                  place.cat_id
                )
              ) {
                appStore.dispatch(actionSetPlaceClicked(place));
                appStore.dispatch(actionBuildTransportPlaces(places));

                if (!data.ref) {
                  data.ref = getRef(data, state.map.reduxMarkers);

                  if (!data.ref && mapPlaces.length > 0) {
                    data.ref = getRef(data, mapPlaces);
                  }
                }

                if (data.ref) {
                  const element = data.ref.leafletElement;

                  setTimeout(() => {
                    updatePopupPosition(element, data);
                  });
                }
              }
            })
            .catch((e) => {
              const error = e.response && e.response.data ? e.response.data.id : e;

              console.warn(error);
            });
        } else {
          axios
            .get(`/api/stations?id=${place.id}`)
            .then((result) => {
              place.stand = result.data;
              appStore.dispatch(actionSetPlaceClicked(place));
            })
            .catch((e) => {
              place.stand = {};
              const error = e.response && e.response.data ? e.response.data.id : e;

              console.warn(error);
            });
        }
      }
    }
  } else if (state.board.thematicPlaces && !pathname.includes("/multimobilities")) {
    history.push({
      pathname,
      search:
        "?place=" + data.id + (params.line ? "&line=" + params.line + (params.stop ? "&stop=" + params.stop : "") : ""),
    });
    appStore.dispatch(actionMarkerClick(data));
  } else {
    clickOnPlaceInList(data, component?.state?.pois, thematicPlaces);
  }

  if (!data.ref) {
    data.ref = getRef(data, state.map.reduxMarkers);

    if (!data.ref && mapPlaces.length > 0) {
      data.ref = getRef(data, mapPlaces);
    }
  }

  // Open popup, even if it's in a cluster
  if (cluster && data.ref && cluster.hasLayer(data.ref.leafletElement)) {
    const element = data.ref.leafletElement;

    // If we are on a cluster, let's zoom in
    cluster.zoomToShowLayer(element, () => {
      element.openPopup();
      setTimeout(() => {
        updatePopupPosition(element);
      });
    });
  } else if (data.ref) {
    setTimeout(() => {
      const element = data.ref.leafletElement;

      element.openPopup();
      setTimeout(() => {
        updatePopupPosition(element, data);
      });
    });
  }

  if (data.lines && component?.state?.groups) {
    const mainGroup = mostImportantGroup(component?.state?.groups, linesModes);

    if (mainGroup && mainGroup !== openedCollapse && tab === 0) {
      appStore.dispatch(actionSetOpenedCollapse(mainGroup));
    }
  }
};

/**
 * Render all map places at a minimum zoom level of 16, such as TCL places, Vélo'v & SNCF stations
 * @param mapReference
 * @param places
 * @returns {*[]}
 */
export const renderMapPlaces = (mapReference, places) => {
  const map = mapReference && mapReference.current && mapReference.current.leafletElement;
  const placesRef = appStore.getState().app.placesRef;
  const placesRefBackground = placesRef ? placesRef.find((p) => p.name === "map-background")?.minZoomLevel : 15;

  if (
    !history.location.pathname.includes("route-calculation") &&
    map &&
    map.getZoom() > placesRefBackground &&
    places
  ) {
    return [
      <MarkerClusterGroup
        key="map-places"
        ref={(ref) => ref && appStore.dispatch(actionSetCluster(ref.leafletElement))}
        removeOutsideVisibleBounds
        showCoverageOnHover={false}
        iconCreateFunction={(cluster) => {
          return L.divIcon({
            html: cluster.getChildCount(),
            className: "lc-cluster",
          });
        }}
      >
        {places.filter((place) => !isNotToClusterised(place.props.place))}
      </MarkerClusterGroup>,
      places.filter((place) => isNotToClusterised(place.props.place)),
    ];
  }
};

/**
 * Build the popup content of a Marker
 * @param state
 * @param data
 * @returns HTMLElement
 */
const buildPopup = (state, data) => {
  const { lock, modules, servicesStations, lines, component, isMobile } = state.app;
  const { options } = component.props;
  const params = getURLSearchParams(history.location);
  const servicesAtStation = [];
  const showGoToRC = REACT_APP_GO_TO_RC_URL || !options || options?.features?.["route-calculation"];

  if (servicesStations) {
    const servicesList = servicesStations.find((s) => s.id === data.id);

    if (servicesList && servicesList.services) {
      Object.keys(servicesList.services).map((serviceType) => {
        return servicesAtStation.push({
          id: servicesList.services[serviceType][0].code,
          name: serviceType,
        });
      });
    }
  }

  const splitName = data?.name?.split(/[()]/);
  const hasSubtitle = splitName?.length > 1;

  return (
    <div
      className={"lc-infobox" + (data.id ? "" : " lc-no-arrow") + (data.divIcon ? ` lc-${data.color}` : "")}
      onMouseLeave={() => appStore.dispatch(actionOutMarker(data))}
      onClick={() => data.name && appStore.dispatch(actionOpenMarker(data))} // Avoid crash if there is no "real" data like sncf-ter entrance map popups
      onKeyPress={(e) => handleKeyPress(e, () => data.name && appStore.dispatch(actionOpenMarker(data)))}
      role="button"
      tabIndex="0"
    >
      {data.name && (
        <div className="lc-infobox-title">
          <span>
            {splitName[0]}
            {hasSubtitle && <em>({data.name.split(/[()]/)[1]})</em>}
          </span>
          <div className={"lc-infobox-title-tools" + (data.pmr && REACT_APP_SHOW_PMR ? " lc-with-pmr" : "")}>
            {REACT_APP_SHOW_PMR && data.pmr
              ? REACT_APP_SHOW_PMR === "pmr" && <div className="lc-is-pmr" />
              : REACT_APP_SHOW_PMR === "no-pmr" && <div className="lc-is-no-pmr" />}
            {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL &&
              JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map((tool) => {
                if (data[tool] === true) {
                  return <div key={`${data.id}_${tool}`} className={`lc-is-${tool}`} />;
                } else {
                  return false;
                }
              })}
            {(REACT_APP_GO_TO_RC_URL || modules.find((m) => m.id === "route-calculation" && m.hide !== true)) &&
              !lock &&
              showGoToRC && (
                <Tippy
                  theme={"latitude"}
                  touch={["hold", 500]}
                  placement={"right"}
                  boundary="window"
                  content={translate("title-go-to-route-calculation")}
                >
                  <div
                    className="lc-tool-route-calculation lc-toolSmall"
                    onClick={(e) => {
                      e.stopPropagation();
                      goToRouteCalculation(data);
                    }}
                    onKeyPress={(e) =>
                      handleKeyPress(e, () => {
                        goToRouteCalculation(data);
                      })
                    }
                    role="button"
                    tabIndex="0"
                  />
                </Tippy>
              )}
            {REACT_APP_STREETVIEW && (
              <Tippy
                theme={"latitude"}
                touch={["hold", 500]}
                placement={"right"}
                boundary="window"
                content={translate("title-streetview-link")}
              >
                {isMobile ? (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${data.coord.lat},${data.coord.lon}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="lc-tool-streetview-link lc-toolSmall" />
                  </a>
                ) : (
                  <div
                    className="lc-tool-streetview-link lc-toolSmall"
                    onClick={() => {
                      const displayStreetview = document.querySelector(".stop-infobox-streetview");

                      document.querySelector(".stop-infobox-streetview").style.display =
                        displayStreetview?.style?.display === "block" ? "none" : "block";
                      document.querySelectorAll(".lc-infobox-lines").forEach((elem) => {
                        if (displayStreetview?.style?.display === "block") {
                          elem.classList.add("lc-active-streetview");
                        } else {
                          elem.classList.remove("lc-active-streetview");
                        }
                      });
                    }}
                  />
                )}
              </Tippy>
            )}
          </div>
        </div>
      )}
      {data.severity && ["blocking", "delays"].includes(data.severity) && (
        <div className={"lc-severity lc-" + data.severity}>
          <div className="lc-disruptionSeverity lc-white">
            <div className="lc-icon" />
          </div>
          {data.severity === "blocking"
            ? `${translate("severity-blocking-stop")} ${
                getLine(lines, {
                  id: params.current.substring(0, params.current.lastIndexOf("_")),
                }).code
              }`
            : translate("severity-delays-stop")}
        </div>
      )}
      {servicesAtStation.length > 0 && (
        <div className="lc-infobox-services-station">
          <span>{translate("infobox-services-title")}</span>
          <div className="lc-services-list">
            {servicesAtStation.map((service) => {
              return (
                <Tippy
                  key={service.id}
                  theme={"latitude"}
                  touch={["hold", 500]}
                  delay={[15, 0]}
                  placement={"right"}
                  boundary="window"
                  content={translate(service.name)}
                >
                  <img src={assetsPath("/assets/images/places/" + service.id + ".svg")} alt={service.name} />
                </Tippy>
              );
            })}
          </div>
        </div>
      )}
      {REACT_APP_STREETVIEW && !isMobile && (
        <div className="lc-streetview stop-infobox-streetview">
          <iframe
            title="streetview"
            style={{ border: 0 }}
            loading="lazy"
            height="350px"
            width="500px"
            src={`https://www.google.com/maps/embed/v1/streetview?key=${REACT_APP_STREETVIEW}&location=${data.coord.lat},${data.coord.lon}`}
          ></iframe>
        </div>
      )}
      <div className="lc-infobox-content">
        {data.cat_id || data instanceof BikeInterface
          ? buildPopupContent(state, data)
          : buildLinesLabels(state, data, "infobox")}
      </div>
    </div>
  );
};

/**
 * Display popup content for places with a cat_id
 * @param state
 * @param data
 * @returns HTMLElement
 */
const buildPopupContent = (state, data) => {
  const { component } = state.app;
  const { options } = component.props; // TODO : retrieve options data directly from postMessage to, in case data is not passed at init

  return (
    <div className="lc-place">
      <UIPoiContent place={data} displayon="map" options={options} />
    </div>
  );
};

/**
 * Build lines labels in infobox / board
 * TODO : recode board side
 * @param state
 * @param data
 * @param key
 * @returns HTMLElement
 */
export const buildLinesLabels = (state, data, key) => {
  let lines = data.lines;
  const { areas, stops, lock, linesModes, size, component } = state.app;
  const { options } = component.props;
  const canChangeLine = options?.features?.["change-line"] === false ? false : true;
  const stopArea = envVarToBool(REACT_APP_PROXIMITY_LINES_AT_STOP) ? areas.find((a) => a.id === data.stop_area) : null;
  const proximityLines = [];

  if (stopArea) {
    for (const l of stopArea.lines) {
      if (!lines.find((line) => line.id === l.id)) {
        proximityLines.push(l);
      }
    }
  }

  // Avoid undefined lines...
  if (!lines) {
    // TODO Add custom info, like addresses for POI (https://latitude-cartagene.atlassian.net/browse/TCL-224)
    return null;
  }

  // SNCF ??
  lines = lines.map((line) => getLine(state.app.lines, line));
  let styleLine = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : "color";
  const canClickLine = !lock && canChangeLine;

  const handleClickLine = (e, line, data, proximity) => {
    e.stopPropagation();

    let proximityStop = null;

    if (proximity) {
      const stopsAtArea = stops.filter((s) => s.stop_area === data.stop_area);

      if (stops) {
        proximityStop = stopsAtArea.find((s) => s.lines.find((l) => l.id === line.id));
      }
    }

    if (!lock && canChangeLine) {
      message({ clicked: "line", id: line.id });
      // ! TODO DON'T GO IN LINES TAB
      appStore.dispatch(actionOnLineSelected(line, proximityStop ? proximityStop : data));
    }
  };

  // Use to know how many rows we should have on our popup
  const gridRows = Math.ceil(lines.length / 2);

  const div = (lines, proximity = false) => (
    <div
      key={key + Math.random()}
      className={
        (key === "infobox" ? "lc-infobox-" : "lc-") +
        "lines lc-" +
        size +
        (styleLine.includes("WithDirection") ? " lc-line-with-direction" : "") +
        (key === "infobox" && data?.id?.startsWith("stop_point:") && styleLine.includes("WithRouteDirection")
          ? " lc-line-with-route-direction"
          : "")
      }
      style={
        styleLine.includes("WithRouteDirection") && data?.id?.startsWith("stop_point:") && key === "infobox"
          ? {
              gridTemplateRows: `repeat(${gridRows}, 1fr)`,
            }
          : {}
      }
    >
      {Object.keys(lines).map((m) =>
        unique(lines[m], "id").map((line) => {
          // Retrieve the global line
          line = getLine(state.app.lines, line);

          if (REACT_APP_LINES_TYPE_EXCEPTIONS) {
            const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS);
            const foundExceptedLine = exceptions.find((e) => e.lines.includes(line.id));

            if (foundExceptedLine) {
              styleLine = foundExceptedLine.type;
            }
          }

          switch (styleLine) {
            case "modeWithDirection":
              const lineMode = linesModes.find((mode) => mode.modes.includes(line.mode));

              return (
                <div
                  className="lc-attribute-line"
                  key={line.id}
                  onClick={(e) => handleClickLine(e, line, data, proximity)}
                  onKeyPress={(e) => handleKeyPress(e, () => handleClickLine(e, line, data, proximity))}
                  role={canClickLine ? "button" : "img"}
                  tabIndex="0"
                  aria-label={translate("aria-line", false, { key: "code", value: line.code })}
                >
                  <div
                    className="lc-line lc-mode"
                    style={{
                      background: "#" + line.color,
                      color: luminance("#" + line.color) > 0.5 ? "#333" : "#fff",
                    }}
                  >
                    {lineMode.name}
                  </div>
                  <div className="lc-name">{line.name}</div>
                </div>
              );
            case "codeWithDirection":
              return (
                <div
                  className="lc-attribute-line"
                  key={line.id}
                  onClick={(e) => handleClickLine(e, line, data, proximity)}
                  onKeyPress={(e) => handleKeyPress(e, () => handleClickLine(e, line, data, proximity))}
                  role={canClickLine ? "button" : "img"}
                  tabIndex="0"
                  aria-label={translate("aria-line", false, { key: "code", value: line.code })}
                >
                  <div
                    className="lc-line lc-code"
                    style={{
                      background: "#" + line.color,
                      color: luminance("#" + line.color) > 0.5 ? "#333" : "#fff",
                    }}
                  >
                    {line.code}
                  </div>
                  <div className="lc-name">{line.name}</div>
                </div>
              );
            case "imageWithRouteDirection":
            case "image":
              return (
                <div
                  className="lc-line"
                  key={line.id}
                  onClick={(e) => handleClickLine(e, line, data, proximity)}
                  onKeyPress={(e) => handleKeyPress(e, () => handleClickLine(e, line, data, proximity))}
                  role={canClickLine ? "button" : "img"}
                  tabIndex="0"
                  aria-label={translate("aria-line", false, { key: "code", value: line.code })}
                >
                  <img
                    src={assetsPath("/assets/images/lines/" + line.code + ".svg")}
                    alt={line.code}
                    aria-hidden="true"
                  />
                  {styleLine === "imageWithRouteDirection" && line.direction && (
                    <div className="lc-name">{line.direction}</div>
                  )}
                </div>
              );
            case "color":
              return (
                <div
                  key={line.id}
                  className="lc-line"
                  onClick={(e) => handleClickLine(e, line, data)}
                  onKeyPress={(e) => handleKeyPress(e, () => handleClickLine(e, line, data))}
                  role={canClickLine ? "button" : "img"}
                  tabIndex="0"
                  aria-label={translate("aria-line", false, { key: "code", value: line.code })}
                >
                  <div className="lc-tools-at-line">
                    {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL &&
                      JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map((tool) => {
                        if (data[tool] && data[tool].length && data[tool].includes(`${line.code}_${line.network}`)) {
                          return <div key={`${data.id}_${tool}`} className={`lc-is-${tool}`} />;
                        } else {
                          return false;
                        }
                      })}
                  </div>
                  <div
                    className="lc-line-code"
                    style={{
                      background: "#" + line.color,
                      color: luminance(line.color) > 0.5 ? "#333" : "#fff",
                    }}
                  >
                    {line.code}
                  </div>
                </div>
              );
            default:
              return "";
          }
        })
      )}
    </div>
  );

  return (
    <>
      {envVarToBool(REACT_APP_CONNECTIONS_TEXT) && (
        <div className="lc-connections-at-stop">{translate("connections-at-stop")}</div>
      )}
      {div(groupLinesByMode(lines, linesModes))}
      {proximityLines.length > 0 && (
        <>
          <div className="lc-connections-at-stop">{translate("proximity-at-stop")}</div>
          {div(groupLinesByMode(proximityLines, linesModes), true)}
        </>
      )}
    </>
  );
};
