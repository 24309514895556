import React from "react";
import styled from "styled-components";
import { assetsPath, translate } from "../services/tools";
import { Page, Header, Title, TitleText, TitleImg, TextInBold } from "./commun";
import { Summary, ItineraryMap } from "./commun";

const SectionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const WalkSection = styled.div``;

const MapContent = styled.div`
  padding-left: 10px;
`;

const Section = (props) => {
  const { section, sections } = props;

  const libelle =
    section.index === 0
      ? translate("departure", false)
      : section.index === sections.length - 1
      ? translate("arrival", false)
      : translate("connection", false, { key: "number", value: section.index });

  return (
    <WalkSection>
      <TextInBold>{libelle}</TextInBold>
      <ItineraryMap size="small" {...props} />
    </WalkSection>
  );
};

const PrintItineraryMapWalk = (props) => {
  const { datas, page } = props;
  const { component, journey } = datas;

  const sections = journey.sections
    .filter(
      (s) =>
        (s.type === "street_network" && ["walking", "bike"].includes(s.mode)) ||
        (s.type === "transfer" && s.transfer_type === "walking" && s.duration > 0)
    )
    .map((s, i) => ({ ...s, index: i }));

  if (sections.length === 1 && sections.length === journey.sections.length) {
    return null;
  }

  return (
    <Page>
      <Header>
        <Title>
          <TitleImg src={assetsPath("/assets/images/menu/route-calculation.svg")} />
          <TitleText>{translate("print-page-title")}</TitleText>
        </Title>
      </Header>
      {page.summary && <Summary component={component} />}
      <MapContent>
        <TextInBold>{translate("print-page-zoom-walking-sections")}</TextInBold>
        <SectionsContainer>
          {sections.map((section, index) => (
            <Section
              key={`section-${index}`}
              section={section}
              sections={sections}
              component={component}
              journey={journey}
            />
          ))}
        </SectionsContainer>
      </MapContent>
    </Page>
  );
};

export default PrintItineraryMapWalk;
