import L from "leaflet";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useLeafletMap } from "use-leaflet";
import styled from "styled-components";
import { useMedia } from "../../hooks/useMedia";
import { assetsPath } from "../../services/tools";
import { primarycolor } from "../../scss/app.scss";

const GeolocationButton = styled.div`
  position: ${(props) => !props.isDesktop && "absolute"};
  right: ${(props) => !props.isDesktop && "0"};
  background-color: ${(props) => (props.color ? props.color : primarycolor)};
  background-image: url(${(props) => assetsPath(props.legendIcon || "/assets/images/geolocation.svg")});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 55%;
  margin-bottom: ${(props) => props.show && "-10px"};
  margin-right: -2px;
  padding: 13px;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

export function Geolocation(props) {
  const map = useLeafletMap();
  const isDesktop = useMedia();
  const [position, setPosition] = useState(null);
  const { color } = props;

  useEffect(() => {
    const geolocation = L.control({
      position: isDesktop ? "bottomright" : "topright",
    });

    const div = L.DomUtil.create("div", "");

    L.DomEvent.disableClickPropagation(div);

    geolocation.onAdd = () => {
      ReactDOM.render(
        <GeolocationButton
          isDesktop={isDesktop}
          color={color}
          onClick={() => {
            if (position && isDesktop) {
              props.onGeolocationSuccess(position);
              return;
            }

            if ("geolocation" in navigator) {
              navigator.geolocation.getCurrentPosition(
                function (position) {
                  setPosition(position);
                  props.onGeolocationSuccess(position);
                },
                null,
                {
                  enableHighAccuracy: false,
                  maximumAge: Infinity,
                }
              );
            } else {
              console.warn("Géolocalisation non dispo");
            }
          }}
        />,
        div
      );
      return div;
    };

    geolocation.addTo(map);

    return () => {
      map.removeControl(div);
    };

    // eslint-disable-next-line
  }, [isDesktop]);

  return null;
}
