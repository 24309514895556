import React, { Component } from "react";
import { Map, TileLayer, Pane, ZoomControl, ScaleControl, GeoJSON, LayerGroup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { detect } from "detect-browser";
import { appStore } from "../store";
import { actionSetMap } from "../actions/app";
import { toggleModalGeneric } from "../actions/modalGeneric";
import { actionSetCluster, actionHandleLegendState, actionSetDisplayMarkerMode } from "../actions/map";
import {
  envVarToBool,
  getURLSearchParams,
  isNotPlacesTabAround,
  assetsPath,
  debug,
  handleKeyPress,
  translate,
  updatePopupPosition,
  isActiveModule,
} from "../services/tools";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { renderMapPlaces } from "../services/map";
import history from "../history";
import L from "leaflet";
import Control from "react-leaflet-control";
import Tippy from "@tippy.js/react";
import { Geolocation } from "./map/Geolocation";
import { controlColor } from "../scss/app.scss";
import UIMapBtnSegmentation from "./styled/UIMapBtnSegmentation";

const {
  REACT_APP_TERRITORY_OUTLINE,
  REACT_APP_HEADER,
  REACT_APP_BASE_MAX_BOUNDS,
  REACT_APP_DEMO,
  REACT_APP_BASE_TILES_URL,
  REACT_APP_ABOVE_TILES_URL,
  REACT_APP_ZOOM,
  REACT_APP_LEGEND,
  REACT_APP_MACARON,
  REACT_APP_GEOLOCATION_CONTROLLER,
  REACT_APP_PROJECT,
  REACT_APP_TYPE,
  REACT_APP_MODALS,
  REACT_APP_STREETVIEW,
  REACT_APP_AREAS_ZOOM_LEVEL
} = process.env;

const browser = detect();

class Leaflet extends Component {
  mapReference = React.createRef();

  state = {
    bounds: null,
    boundsOptions: null,
    circle: null,
    clusters: null,
    events: {},
    infoboxs: [],
    infoboxsTerminus: [],
    markers: [],
    markersMap: [],
    markersPlaces: [],
    markersRER: [],
    pin: null,
    polygons: [],
    polylines: [],
    polylineDecorators: [],
    terminus: false,
    random: null, // TODO, use to redraw the map for onMoveEnd event...
  };

  componentDidMount() {
    appStore.dispatch(actionSetMap(this));
  }

  componentDidUpdate(prevProps) {
    if (this.props.onScreen !== prevProps.onScreen) {
      debug({ message: `Is map on screen : ${this.props.onScreen}` }, "info", "Map on screen");
    }

    if (this.props.languageFile !== prevProps.languageFile) {
      if (REACT_APP_MODALS && JSON.parse(REACT_APP_MODALS).includes("legal-notice")) {
        this.mapReference.current.leafletElement.attributionControl.setPrefix(
          `<span id="lc-legal-notice">${translate("legal-notice")}</span>`
        );

        document.querySelector("#lc-legal-notice").addEventListener("click", () => {
          appStore.dispatch(toggleModalGeneric("legal-notice"));
        });
      } else {
        this.mapReference.current.leafletElement.attributionControl.setPrefix(
          '<div class="leaflet-control-attribution leaflet-control"><a href="https://leafletjs.com" target="_blank" rel="noopener">Leaflet</a> | © <a href="https://latitude-cartagene.com" target="_blank" rel="noopener">Latitude-Cartagène</a> | © <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener">OpenStreetMap</a></div>'
        );
      }
    }
  }

  render() {
    const {
      events,
      bounds,
      boundsOptions,
      polygons,
      polylines,
      polylineDecorators,
      circle,
      pin,
      markers,
      markersMap,
      markersPlaces,
      markersRER,
    } = this.state;

    const {
      territoryOutline,
      entranceMapMarkers,
      heavyLines,
      hideHeavyLines,
      customLines,
      customMarkers,
      reduxMarkers,
      aroundCircles,
      aroundPin,
      selectedLine,
      transportPlaces,
      mapPlaces,
      places,
      isMobile,
      isLegendOpen,
      bikePaths,
      bikes,
      reactTourismPartnersStops,
      reactLines,
      showBoard,
      zoom,
      center,
      config,
      markerMode,
      isExpandedMap,
      component,
      openedMarker,
      modules,
    } = this.props;

    const pathname = history.location.pathname;
    const search = history.location.search;
    const params = getURLSearchParams(history.location);
    const map = this.mapReference?.current?.leafletElement;
    const markerModeChanged = component?.props?.moduleData?.markerModeChanged || component?.props?.markerModeChanged;
    const thematicDontShowStops = component?.props?.moduleData?.dontShowStops;
    const displayHeavyLinesOnCurrentLine = component?.props?.moduleData?.displayHeavyLinesOnCurrentLine;

    const disableHeavyLinesOnMobilities =
      isActiveModule("multimobilities") &&
      modules.find((m) => m.id === "multimobilities")?.mobilities?.find((m) => String(m.id) === String(params.mob))
        ?.disableHeavyLines === true;

    // TODO zoom duplicate but in state it's not updated
    let zoomTmp = null;

    if (map) {
      zoomTmp = map.getZoom();
    }

    let entranceItems = [];

    if (entranceMapMarkers) {
      entranceItems = entranceMapMarkers.filter((marker) => {
        if (
          !marker.props.zoom ||
          (marker.props.zoom.min && !marker.props.zoom.max && marker.props.zoom.min <= zoomTmp) ||
          (!marker.props.zoom.min && marker.props.zoom.max && marker.props.zoom.max >= zoomTmp) ||
          (marker.props.zoom.min <= zoomTmp && marker.props.zoom.max >= zoomTmp)
        ) {
          return marker;
        }

        return false;
      });
    }

    // Zoom handling
    if (markerModeChanged) {
      setTimeout(() => {
        if (zoomTmp < markerModeChanged && markerMode === "default") {
          appStore.dispatch(actionSetDisplayMarkerMode("small"));

          if (openedMarker?.ref) {
            updatePopupPosition(openedMarker.ref.leafletElement, openedMarker);
          }
        } else if (zoomTmp >= markerModeChanged && markerMode !== "default") {
          appStore.dispatch(actionSetDisplayMarkerMode("default"));

          if (openedMarker?.ref) {
            updatePopupPosition(openedMarker.ref.leafletElement, openedMarker);
          }
        }
      });
    }

    // Detect Safari or Edge 17 browsers to fallback tiles on PNG
    const ext =
      browser.name === "safari" ||
      browser.os === "iOS" ||
      (browser.name === "edge" && browser.version.startsWith("17."))
        ? "png"
        : "webp";

    return (
      <>
        <Map
          ref={this.mapReference}
          className={
            "lc-mapContainer" +
            (envVarToBool(REACT_APP_HEADER) ? " lc-with-header" : "") +
            (!showBoard ? " lc-no-board" : "") +
            (isExpandedMap ? " lc-expanded-map" : "")
          }
          bounds={bounds}
          maxBounds={
            REACT_APP_BASE_MAX_BOUNDS ? JSON.parse(REACT_APP_BASE_MAX_BOUNDS)[isMobile ? "mobile" : "desktop"] : null
          }
          boundsOptions={boundsOptions}
          zoomControl={false}
          center={center}
          minZoom={+JSON.parse(REACT_APP_ZOOM)["min"]}
          maxZoom={+JSON.parse(REACT_APP_ZOOM)["max"]}
          zoom={zoom}
          scrollWheelZoom={config.scrollWheelZoom}
          dragging={config.dragging}
          {...events}
        >
          {REACT_APP_DEMO && (
            <TileLayer
              errorTileUrl={assetsPath("/assets/images/blank.png")} // Grey tile if 404
              url={`http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png`}
            />
          )}
          {REACT_APP_BASE_TILES_URL && (
            <TileLayer
              errorTileUrl={assetsPath("/assets/images/blank.png")} // Grey tile if 404
              url={
                REACT_APP_BASE_TILES_URL === "mbtiles"
                  ? `https://tiles.lc.tools/services/${REACT_APP_PROJECT}/tiles/{z}/{x}/{y}.png`
                  : REACT_APP_BASE_TILES_URL === "mbtiles-rendering-test"
                  ? `https://tiles.lc.tools/services/${REACT_APP_PROJECT}-rendering-test/tiles/{z}/{x}/{y}.png`
                  : `${REACT_APP_BASE_TILES_URL}/${ext}/{z}/{x}/{y}.${ext}`
              }
            />
          )}
          {REACT_APP_ABOVE_TILES_URL && (
            <Pane name="tilelayer-above">
              <TileLayer
                errorTileUrl={assetsPath("/assets/images/blank.png")} // Grey tile if 404
                url={
                  REACT_APP_ABOVE_TILES_URL === "mbtiles"
                    ? `https://tiles.lc.tools/services/${REACT_APP_PROJECT}-above/tiles/{z}/{x}/{y}.png`
                    : REACT_APP_ABOVE_TILES_URL === "mbtiles-rendering-test"
                    ? `https://tiles.lc.tools/services/${REACT_APP_PROJECT}-above-rendering-test/tiles/{z}/{x}/{y}.png`
                    : `${REACT_APP_ABOVE_TILES_URL}/${ext}/{z}/{x}/{y}.${ext}`
                }
              />
            </Pane>
          )}
          {!isMobile && <ScaleControl position={"bottomright"} imperial={false} />}
          {!isMobile && (
            <ZoomControl
              position={"bottomright"}
              zoomInTitle={translate("title-zoom-in")}
              zoomOutTitle={translate("title-zoom-out")}
            />
          )}
          {envVarToBool(REACT_APP_MACARON) && !isMobile && (
            <img className={"lc-leaflet-map-macaron"} src={assetsPath("/assets/images/macaron.svg")} alt="macaron" />
          )}
          {envVarToBool(REACT_APP_GEOLOCATION_CONTROLLER) && isMobile && (
            <Geolocation
              color={controlColor}
              onGeolocationSuccess={(position) => {
                setTimeout(() => {
                  history.push(`/around?from=${position.coords.latitude};${position.coords.longitude}`);
                });
              }}
            />
          )}
          {REACT_APP_LEGEND && (
            <Control
              className={"lc-leaflet-control-legend"}
              position={isMobile ? "topright" : "bottomright"}
              updateWhenIdle={true}
            >
              <Tippy theme={"latitude"} touch={["hold", 500]} placement={"left"} boundary="window" content={"Légende"}>
                <div
                  onClick={() => appStore.dispatch(actionHandleLegendState(isLegendOpen))}
                  onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(actionHandleLegendState(isLegendOpen)))}
                  role="button"
                  tabIndex="0"
                >
                  <img src={assetsPath("/assets/images/legend-item.svg")} height={25} width={25} alt="legend" />
                </div>
              </Tippy>
            </Control>
          )}
          {REACT_APP_MODALS && !isMobile && JSON.parse(REACT_APP_MODALS).includes("add-entry") && (
            <Control
              className={"lc-leaflet-control-add-entry"}
              position={isMobile ? "topright" : "bottomright"}
              updateWhenIdle={true}
            >
              <Tippy
                theme={"latitude"}
                touch={["hold", 500]}
                placement={"left"}
                boundary="window"
                content={translate("add-entry")}
              >
                <div
                  onClick={() => appStore.dispatch(toggleModalGeneric("add-entry"))}
                  onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(toggleModalGeneric("add-entry")))}
                  role="button"
                  tabIndex="0"
                >
                  <img src={assetsPath("/assets/images/add-entry-item.svg")} height={25} width={25} alt="add-entry" />
                </div>
              </Tippy>
            </Control>
          )}
          {REACT_APP_MODALS && JSON.parse(REACT_APP_MODALS).includes("tutorial") && (
            <Control
              className={"lc-leaflet-control-tutorial"}
              position={isMobile ? "topright" : "bottomright"}
              updateWhenIdle={true}
            >
              <Tippy
                theme={"latitude"}
                touch={["hold", 500]}
                placement={"left"}
                boundary="window"
                content={translate("tutorial")}
              >
                <div
                  onClick={() => appStore.dispatch(toggleModalGeneric("tutorial"))}
                  onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(toggleModalGeneric("tutorial")))}
                  role="button"
                  tabIndex="0"
                >
                  <img src={assetsPath("/assets/images/tutorial.svg")} height={25} width={25} alt="display-tutorial" />
                </div>
              </Tippy>
            </Control>
          )}
          {!isMobile && REACT_APP_STREETVIEW && (
            <Control className={"lc-leaflet-control-streetview"} position={"bottomright"} updateWhenIdle={true}>
              <Tippy
                theme={"latitude"}
                touch={["hold", 500]}
                placement={"left"}
                boundary="window"
                content={translate("google-streetview")}
              >
                <div role="button" tabIndex="0">
                  <img
                    src={assetsPath("/assets/images/streetview.svg")}
                    height={25}
                    width={25}
                    alt="google-streetview"
                  />
                </div>
              </Tippy>
            </Control>
          )}
          {/* // TODO TOOLS */}
          {territoryOutline && (
            <GeoJSON
              data={territoryOutline}
              style={config.territory ? config.territory : JSON.parse(REACT_APP_TERRITORY_OUTLINE)}
            />
          )}

          {customLines.length > 0 ? (
            <LayerGroup>
              {customLines} {customMarkers}
            </LayerGroup>
          ) : (
            <LayerGroup>
              {!pathname.includes("route-calculation") &&
                (!search.includes("current") ||
                  (search.includes("current") && displayHeavyLinesOnCurrentLine === true)) &&
                !hideHeavyLines &&
                !disableHeavyLinesOnMobilities &&
                heavyLines}
              {!pathname.includes("route-calculation") &&
                (!search.includes("current") ||
                  (search.includes("current") && displayHeavyLinesOnCurrentLine === true)) &&
                !hideHeavyLines &&
                !disableHeavyLinesOnMobilities &&
                entranceItems}
              {customMarkers && (
                <Pane
                  name="custom-markers-cluster-pane"
                  style={isActiveModule("thematic") ? { zIndex: 600 } : { zIndex: 400 }}
                >
                  <MarkerClusterGroup
                    key="custom-markers"
                    ref={(ref) => ref && appStore.dispatch(actionSetCluster(ref.leafletElement))}
                    removeOutsideVisibleBounds
                    showCoverageOnHover={false}
                    clusterPane="custom-markers-cluster-pane"
                    iconCreateFunction={(cluster) => {
                      return L.divIcon({
                        html: cluster.getChildCount(),
                        className: "lc-cluster",
                      });
                    }}
                  >
                    {customMarkers.filter((marker) => marker.props?.place?.clusterized)}
                  </MarkerClusterGroup>
                </Pane>
              )}
              {customMarkers &&
                customMarkers.filter((marker) => !marker.props?.place || !marker.props?.place.clusterized)}
              {bikePaths} {bikes}
              {polygons} {polylines} {polylineDecorators}
              {reactLines}
              {selectedLine}
              {aroundCircles} {aroundPin}
              {circle} {pin}
              {markersMap}
              {markers} {markersPlaces} {markersRER}
              {reactTourismPartnersStops}
              {transportPlaces && (
                <Pane name="cluster-pane" style={isActiveModule("thematic") ? { zIndex: 600 } : { zIndex: 400 }}>
                  <MarkerClusterGroup
                    key="transport-places"
                    ref={(ref) => ref && appStore.dispatch(actionSetCluster(ref.leafletElement))}
                    removeOutsideVisibleBounds
                    showCoverageOnHover={false}
                    clusterPane="cluster-pane"
                    iconCreateFunction={(cluster) => {
                      return L.divIcon({
                        html: cluster.getChildCount(),
                        className: "lc-cluster",
                      });
                    }}
                  >
                    {transportPlaces.filter((place) => place.props.place.clusterized)}
                  </MarkerClusterGroup>
                </Pane>
              )}
              {transportPlaces && transportPlaces.filter((place) => !place.props.place.clusterized)}
              {places}
              {(!transportPlaces || transportPlaces.length === 0) &&
                !search.includes("current=") &&
                !search.includes("mob=") &&
                !isNotPlacesTabAround(places) &&
                renderMapPlaces(this.mapReference, mapPlaces)}
              {params.current || params.line
                ? reduxMarkers
                : (!zoomTmp || zoomTmp >= REACT_APP_AREAS_ZOOM_LEVEL) && thematicDontShowStops !== true && !selectedLine && reduxMarkers}
            </LayerGroup>
          )}
        </Map>
        {REACT_APP_TYPE === "segmentation" && <UIMapBtnSegmentation map={this} />}
      </>
    );
  }
}

export default Leaflet;
