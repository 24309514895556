import axios from "../middlewares/axios";
import { detect } from "detect-browser";
import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { GeoJSON, Map, ScaleControl, TileLayer, ZoomControl } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import {
  actionSetNetworkMapHeavyLines,
  actionSetNetworkMapRef,
  actionSetNetwotkSelectedLine,
  actionSetSliderUrlLeft,
  actionSetSliderUrlRight,
} from "../actions/network";
import { useMedia } from "../hooks/useMedia";
import { useNetworkLineSelected } from "../hooks/useNetworkLineSelected";
import { useSlider } from "../hooks/useSlider";
import { envVarToBool, debug, translate } from "../services/tools";
import { getBoardBoundingClientRect } from "../utils/tools";
import { Legend } from "./map/Legend";
import { Line } from "./map/Line";
import { Stop } from "./map/Stop";
import { primarycolor } from "./../scss/app.scss";
import { useOnScreen } from "../hooks/useOnScreen";
import { useRouter } from "../hooks/useRouter";
import { appStore } from "../store";

const {
  REACT_APP_BASE_TILES_URL,
  REACT_APP_HEADER,
  REACT_APP_SLIDER,
  REACT_APP_HEAVY_LINES,
  REACT_APP_ZOOM,
  REACT_APP_START_POINT,
} = process.env;

const browser = detect();

const LeafletNetwork = (props) => {
  const mapRef = useRef();
  const dispatch = useDispatch();
  const isDesktop = useMedia();
  const { hash, linesModesNetwork, linesNetwork } = useSelector((state) => state.app);
  const { entranceMapMarkers } = useSelector((state) => state.map);
  const { slider, line, town, heavyLines, networkTownsLines } = useSelector((state) => state.network);

  const defaultCenter = props.startOnMobile
    ? JSON.parse(REACT_APP_START_POINT).mobile
    : JSON.parse(REACT_APP_START_POINT).desktop;

  const zoom = props.startOnMobile ? JSON.parse(REACT_APP_ZOOM).mobile : JSON.parse(REACT_APP_ZOOM).desktop;
  const minZoom = +JSON.parse(REACT_APP_ZOOM)["min"];
  const maxZoom = +JSON.parse(REACT_APP_ZOOM)["max"];
  const selectedLine = useNetworkLineSelected();
  const [center, setCenter] = useState(defaultCenter);
  const sliderRef = useRef(null);
  const [networkTownsPolylines, setNetworkTownsPolylines] = useState([]);
  const [networkTownsStops, setNetworkTownsStops] = useState([]);
  const [rootRef, onScreen] = useOnScreen();
  const [showLegend, setShowLegend] = useState(false);
  const overlayLeft = useRef(null);
  const overlayRight = useRef(null);
  const urlLeft = useSelector((state) => state.network.sliderUrls.left);
  const urlRight = useSelector((state) => state.network.sliderUrls.right);
  const router = useRouter();
  const sliderParsed = REACT_APP_SLIDER !== undefined ? JSON.parse(REACT_APP_SLIDER) : {};
  const sliderModes = sliderParsed.modes;

  useSlider(sliderRef, overlayLeft, overlayRight, mapRef);

  // Detect Safari or Edge 17 browsers to fallback tiles on PNG
  const ext =
    browser.name === "safari" || browser.os === "iOS" || (browser.name === "edge" && browser.version.startsWith("17."))
      ? "png"
      : "webp";

  useEffect(() => {
    debug({ message: `Is map on screen : ${onScreen}` }, "info", "Map on screen - network");
  }, [onScreen]);

  useEffect(() => {
    const { left, right } = router.query;

    if (sliderModes?.length > 0) {
      if (left === right) {
        appStore.dispatch(actionSetSliderUrlLeft(sliderModes[0]));
        appStore.dispatch(actionSetSliderUrlRight(sliderModes[1]));
      } else {
        appStore.dispatch(actionSetSliderUrlLeft(left && sliderModes.includes(left) ? left : sliderModes[0]));
        appStore.dispatch(actionSetSliderUrlRight(right && sliderModes.includes(right) ? right : sliderModes[1]));
      }
    }

    // eslint-disable-next-line
  }, [router]);

  /**
   * Boot effect :
   *  - Setup the map ref
   *  - Attribution
   *  - Heavy lines
   * Cleanup effect : Destroy all network map reference on the store
   */
  useEffect(() => {
    const drawHeavyLines = async () => {
      const polylines = [];

      if (REACT_APP_HEAVY_LINES) {
        for (const heavy of JSON.parse(REACT_APP_HEAVY_LINES).reverse()) {
          const line = linesNetwork.find((l) => l.id === heavy);

          if (line !== undefined) {
            const lineMode = linesModesNetwork.find((mode) => mode.modes === line.type);
            const modeName = lineMode.show_like ? lineMode.show_like : lineMode.name;

            const folder = ["new", "modified", "modified_line"].includes(modeName)
              ? "routes/future/lines"
              : "routes/current/lines";

            const response = await axios.get(`/api/file?folder=${folder}&ext=geojson&name=${line.code}~${hash}`);

            if (response && response.data && response.data.features) {
              for (const [key, feature] of Object.entries(response.data.features)) {
                polylines.push(
                  <GeoJSON
                    key={line.code + key}
                    data={feature}
                    style={{
                      color: "#" + line.color,
                      weight: 6,
                      interactive: false,
                      //smoothFactor: 0.85,
                      // No more dashed for now
                      // weight: feature.properties.dashed ? 4 : 6,
                      // dashArray: feature.properties.dashed && '8, 5',
                      // dashOffset: feature.properties.dashed && '0',
                      // lineCap: feature.properties.dashed ? 'miter-clip' : 'round',
                      // lineJoin: feature.properties.dashed ? 'miter-clip' : 'round'
                    }}
                    onEachFeature={(_, layer) => setTimeout(() => layer.bringToBack())}
                  />
                );
              }
            }
          }
        }
      }

      dispatch(actionSetNetworkMapHeavyLines(polylines));
    };

    // Push the current network map into redux store
    dispatch(actionSetNetworkMapRef(mapRef));

    // Custom the default attribution to add target blank
    mapRef.current.leafletElement.attributionControl.setPrefix(
      '<a href="https://leafletjs.com" target="_blank" rel="noopener">Leaflet</a>'
    );

    drawHeavyLines();

    return () => {
      // Clear the redux store
      dispatch(actionSetNetworkMapRef(null));
      dispatch(actionSetNetworkMapHeavyLines(null));
      dispatch(actionSetNetwotkSelectedLine(null));
    };

    // eslint-disable-next-line
  }, [dispatch, linesNetwork]);

  useEffect(() => {
    const drawTownsNetworkLines = async () => {
      const polylines = [];
      const stops = [];

      for (const heavy of networkTownsLines) {
        const line = linesNetwork.find((l) => l.code === heavy);

        if (line === undefined) {
          continue;
        }

        const modeName = linesModesNetwork.find((mode) => mode.modes === line.type).name;

        const folder = ["new", "modified", "modified_line"].includes(modeName)
          ? "routes/future/lines"
          : "routes/current/lines";

        const response = await axios.get(`/api/file?folder=${folder}&ext=geojson&name=${line.code}~${hash}`);

        if (response) {
          for (const [key, feature] of Object.entries(response.data.features)) {
            polylines.push(
              <Line
                key={line.code + key + "networktownslines"}
                data={feature}
                style={{
                  color: "#" + line.color,
                  weight: 4,
                  interactive: false,
                }}
              />
            );
          }
        }

        const folderStops = ["new", "modified", "modified_line"].includes(modeName)
          ? "routes/future/stops"
          : "routes/current/stops";

        const responseStops = await axios.get(`/api/file?folder=${folderStops}&ext=geojson&name=${line.code}~${hash}`);

        if (responseStops) {
          for (const [keyStop, featureStop] of Object.entries(responseStops.data.features)) {
            stops.push(<Stop key={line.code + keyStop + "networktownsStops"} data={featureStop} line={line} />);
          }
        }
      }

      setNetworkTownsPolylines(polylines);
      setNetworkTownsStops(stops);
    };

    drawTownsNetworkLines();

    // eslint-disable-next-line
  }, [networkTownsLines]);

  /**
   * Effect used to update center and recalculate it's position on mobile or desktop
   */
  useEffect(() => {
    const board = getBoardBoundingClientRect();
    const latlng = L.latLng(defaultCenter);
    const point = mapRef.current.leafletElement.latLngToContainerPoint(latlng);
    const offsetPoint = isDesktop ? L.point([point.x - board.width / 2, point.y]) : L.point([point.x, point.y]);

    setCenter(mapRef.current.leafletElement.containerPointToLatLng(offsetPoint));

    // eslint-disable-next-line
  }, [isDesktop]);

  /**
   * Effect used to display or not the legend on the map
   */
  useEffect(() => {
    if (selectedLine) {
      const lineMode = linesModesNetwork.find((mode) => mode.modes === selectedLine.type);

      if (lineMode.name === "modified_line" || lineMode.show_like === "modified_line") {
        setShowLegend(true);
      }
    } else {
      setShowLegend(false);
    }

    // eslint-disable-next-line
  }, [selectedLine]);

  // Map attribution
  const attribution =
    '&copy; <a href="https://latitude-cartagene.com" target="_blank" rel="noopener">Latitude-Cartagène</a> | &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener">OpenStreetMap</a>';

  return (
    <div className="lc-map" ref={rootRef}>
      {sliderParsed?.titles && (
        <div className="lc-titles">
          <div id="lc-titleLeft" className="lc-titleLeft">
            {translate("slider-label-" + urlLeft)?.toUpperCase()}
          </div>
          <div id="lc-titleRight" className="lc-titleRight">
            {translate("slider-label-" + urlRight)?.toUpperCase()}
          </div>
        </div>
      )}
      <Map
        ref={mapRef}
        center={center}
        className={
          "lc-mapContainer" +
          (envVarToBool(REACT_APP_HEADER) ? " lc-with-header" : "") +
          (!props.showBoard ? " lc-no-board" : "") +
          (!props.isExpandedMap ? " lc-expanded-map" : "")
        }
        zoom={zoom}
        minZoom={minZoom}
        maxZoom={maxZoom}
        zoomControl={false}
      >
        {REACT_APP_BASE_TILES_URL !== "" && (
          <TileLayer url={REACT_APP_BASE_TILES_URL + "/" + ext + "/{z}/{x}/{y}." + ext} attribution={attribution} />
        )}
        {slider && (
          <>
            <TileLayer
              ref={overlayLeft}
              className="lc-overlay"
              url={`${sliderParsed.url}/${urlLeft}/tiles/{z}/{x}/{y}.png`}
              attribution={attribution}
            />
            <TileLayer
              ref={overlayRight}
              className="lc-overlay"
              url={`${sliderParsed.url}/${urlRight}/tiles/{z}/{x}/{y}.png`}
              attribution={attribution}
            />
          </>
        )}

        {isDesktop && (
          <>
            <ScaleControl position={"bottomright"} imperial={false} />
            <ZoomControl position={"bottomright"} />
          </>
        )}

        {showLegend && <Legend line={selectedLine} />}
        {town && town.geometry !== undefined && (
          <Line
            centerOnDraw
            returnToOriginalBoundsOnDestroy
            data={town.geometry}
            style={{
              color: primarycolor,
              fillColor: "#999",
              weight: 2,
              stroke: true,
              opacity: 0.3,
              interactive: false,
            }}
            onEachFeature={(_, layer) => setTimeout(() => layer.bringToBack())}
          />
        )}

        {heavyLines}
        {entranceMapMarkers}
        {networkTownsPolylines}
        {networkTownsStops}
        {line}
      </Map>

      {slider && (
        <div className="lc-slider" ref={sliderRef}>
          <div className="lc-before">
            <span>{translate("slider-label-" + urlLeft)?.toUpperCase()}</span>
          </div>
          <div className="lc-between" />
          <div className="lc-after">
            <span>{translate("slider-label-" + urlRight)?.toUpperCase()}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeafletNetwork;
