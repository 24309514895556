import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionSetAllLinesColorType } from "../../actions/map";
import { buildAllLines } from "../../services/map";
import { handleKeyPress } from "../../services/tools";

const UIMapBtnSegmentation = (props) => {
  const dispatch = useDispatch();
  const allLinesColorType = useSelector((state) => state.map.allLinesColorType);
  const allLinesSelected = useSelector((state) => state.map.allLinesSelected);
  const { map } = props;

  return (
    <div className="lc-btn-segmentation-dl">
      <div
        className={"lc-segmentation" + (allLinesColorType === "segmentation" ? " active" : "")}
        onClick={() => {
          dispatch(actionSetAllLinesColorType());
          setTimeout(() => buildAllLines(map, allLinesSelected, true));
        }}
        onKeyPress={(e) =>
          handleKeyPress(e, () => {
            dispatch(actionSetAllLinesColorType());
            setTimeout(() => buildAllLines(map, allLinesSelected, true));
          })
        }
        role="button"
        tabIndex="0"
      >
        Segmentation
      </div>
      <div
        className={"lc-ddl" + (allLinesColorType === "ddl" ? " active" : "")}
        onClick={() => {
          dispatch(actionSetAllLinesColorType());
          setTimeout(() => buildAllLines(map, allLinesSelected, true));
        }}
        onKeyPress={(e) =>
          handleKeyPress(e, () => {
            dispatch(actionSetAllLinesColorType());
            setTimeout(() => buildAllLines(map, allLinesSelected, true));
          })
        }
        role="button"
        tabIndex="0"
      >
        Direction de lignes
      </div>
    </div>
  );
};

export default UIMapBtnSegmentation;
