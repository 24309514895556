export const actionBuildTerritoryOutline = (geojson) => ({
  type: "BUILD_TERRITORY_OUTLINE",
  geojson,
});

export const actionBuildEntranceMap = (geojson) => ({
  type: "BUILD_ENTRANCE_MAP",
  geojson,
});

export const actionSetReduxMarkers = (markers) => ({
  type: "SET_REDUX_MARKERS",
  markers,
});

export const actionSetCustomMarkers = (markers) => ({
  type: "SET_CUSTOM_MARKERS",
  markers,
});

export const actionAddCustomMarkers = (markers) => ({
  type: "ADD_CUSTOM_MARKERS",
  markers,
});

export const actionSetCustomMarkerEvent = (id, event) => ({
  type: "SET_CUSTOM_MARKERS_EVENT",
  id,
  event,
});

export const actionSetHeavyLines = (lines) => ({
  type: "SET_HEAVY_LINES",
  lines,
});

export const actionHideHeavyLines = (hide) => ({
  type: "HIDE_HEAVY_LINES",
  hide,
});

export const actionSetCustomLines = (lines) => ({
  type: "SET_CUSTOM_LINES",
  lines,
});

export const actionMarkerClick = (marker) => ({
  type: "ON_MARKER_CLICK",
  marker,
});

export const actionSetLineSelected = (line) => ({
  type: "SET_LINE_SELECTED",
  line,
});

export const actionSetLineSelectedPath = (path) => ({
  type: "SET_LINE_SELECTED_PATH",
  path,
});

export const actionSetTransportPlaces = (places) => ({
  type: "SET_TRANSPORT_PLACES",
  places,
});

export const actionSetPublicPlaces = (places) => ({
  type: "SET_PUBLIC_PLACES",
  places,
});

export const actionSetMapPlaces = (places) => ({
  type: "SET_MAP_PLACES",
  places,
});

export const actionSetCluster = (cluster) => ({
  type: "SET_CLUSTER",
  cluster,
});

export const actionSetEntranceMapMarkers = (markers) => ({
  type: "SET_ENTRANCE_MAP_MARKERS",
  markers,
});

export const actionHandleLegendState = (isLegendOpen) => ({
  type: "HANDLE_LEGEND_STATE",
  isLegendOpen,
});

export const actionSetBikePaths = (paths) => ({
  type: "SET_BIKE_PATHS",
  paths,
});

export const actionSetMapBikes = (bikes) => ({
  type: "SET_MAP_BIKES",
  bikes,
});

export const actionSetEntrancePopup = (popup) => ({
  type: "SET_ENTRANCE_POPUP",
  popup,
});

export const actionSetTourimsPartnersStops = (stops) => ({
  type: "SET_TOURISM_PARTNERS_STOPS",
  stops,
});

export const actionSetLinesToDisplay = (polylines) => ({
  type: "SET_LINES_TO_DISPLAY",
  polylines,
});

export const actionSetZoom = (zoom) => ({
  type: "SET_ZOOM",
  zoom,
});

export const actionSetCenter = (center) => ({
  type: "SET_CENTER",
  center,
});

export const actionSetAroundPin = (geoloc) => ({
  type: "SET_AROUND_PIN",
  aroundPin: geoloc.pin,
  aroundCircles: geoloc.circles,
});

export const actionSetDisplayMarkerMode = (mode) => ({
  type: "SET_DISPLAY_MARKER_MODE",
  mode,
});

export const actionSetAllLinesGeojson = (geojson) => ({
  type: "SET_ALL_LINES_GEOJSON",
  geojson,
});

export const actionSetAllLinesSelected = (selected) => ({
  type: "SET_ALL_LINES_SELECTED",
  selected,
});

export const actionSetAllLinesintersecGeojson = (geojson) => ({
  type: "SET_ALL_LINESINTERSEC_GEOJSON",
  geojson,
});

export const actionSetAllLinesColorType = (colorType) => ({
  type: "SET_ALL_LINES_COLOR_TYPE",
});
