import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { assetsPath, translate, isSystemUS } from "../services/tools";
import { formatDate } from "../utils/tools";
import {
  Page,
  Header,
  Title,
  TitleText,
  TitleImg,
  Text,
  TextInBold,
  Footer,
  IconWarning,
  // HeaderJourney,
  Summary,
} from "./commun";

const Roadmap = styled.div`
  padding: 10px !important;
  font-size: 1.02em !important;

  .lc-roadmap-summary {
    width: 400px;
    margin-left: 0px !important;
  }

  .lc-section-notes {
    width: 600px;
  }

  .lc-disruptions {
    display: ${(props) => (props.openDisruptions ? "block !important" : "none")};
    width: 400px;

    #lc-disruptions {
      overflow: visible !important;
      height: unset !important;
      visibility: visible !important;
    }

    .lc-disruptions-head {
      display: none !important;
    }
  }
`;

const RoadmapTitle = styled.div`
  margin-bottom: 20px;
  margin-top: 0px;
`;

const Texts = styled.div`
  padding-left: 10px;
`;

const PrintItineraryRoadmap = (props) => {
  const language = useSelector((state) => state.app.language);
  const date = formatDate(new Date(), isSystemUS(language) ? "m/d/y" : "d/m/y");
  const hours = formatDate(new Date(), isSystemUS(language) ? "h:m a" : "h:m");
  const { datas, page } = props;
  const { journey, component } = datas;

  if (page.openStops) {
    journey.sections
      .filter((s) => s.type === "public_transport")
      .forEach((s) => {
        s.opened = true;
      });
  }

  if (page.openWalkingSections) {
    journey.sections
      .filter((s) => s.type === "street_network" || s.type === "transfer")
      .forEach((s) => {
        s.opened = true;
      });
  }

  const hasDisruptions =
    journey.disruptions && journey.disruptions.filter((disruption) => disruption.severity === "blocking").length > 0;

  return (
    <Page>
      <Header>
        <Title>
          <TitleImg src={assetsPath("/assets/images/menu/route-calculation.svg")} />
          <TitleText>{translate("print-page-title")}</TitleText>
        </Title>
      </Header>
      <Texts>
        <Text>{translate("print-page-date", false, { key: "date", value: date }, { key: "hours", value: hours })}</Text>
        <Text dangerouslySetInnerHTML={{ __html: translate("print-page-check-website") }}></Text>
      </Texts>

      <Summary component={component} />

      <Roadmap
        className="lc-roadmap lc-scroll"
        data-lc-scroll="scroll"
        data-lc-roadmap
        openDisruptions={page.openDisruptions}
      >
        <RoadmapTitle>
          <TextInBold>{translate("print-page-roadmap-details", false)}</TextInBold>
        </RoadmapTitle>
        {/* <HeaderJourney component={component} journey={journey} modes={modes} /> */}
        {journey.sections.map((section, index) => component.renderSection(section, index))}
      </Roadmap>
      {hasDisruptions && (
        <Footer>
          <IconWarning src={assetsPath("/assets/images/disruptions/blocking.svg")} />
          <span dangerouslySetInnerHTML={{ __html: translate("print-page-has-disruptions") }}></span>
        </Footer>
      )}
    </Page>
  );
};

export default PrintItineraryRoadmap;
