import { useEffect, useState } from "react";
import { useWindowSize } from "./useWindowSize";
import { useMedia } from "./useMedia";
import exportedStyles from "../scss/app.scss";
import { getBoardBoundingClientRect } from "../utils/tools";

/**
 * Retrive the computed max height from a given component (ref) depending of the current window height
 * @param {React.Ref} ref component ref
 * @param {*} update value used to force update the height calculation
 */
export function useComponentMaxHeight(ref, update = false) {
  const windowSize = useWindowSize();
  const [maxHeight, setMaxHeight] = useState(null);
  const isDesktop = useMedia();
  const { boardMarginTop, headerHeightMobile } = exportedStyles;

  /**
   * Boot effect : apply a vertical scroll to our component ref
   */
  useEffect(() => {
    const current = ref.current;

    if (current && isDesktop) {
      current.style.overflowY = "auto";
    }

    return () => {
      if (current) {
        current.style.maxHeight = "initial";
      }
    };

    // eslint-disable-next-line
  }, [ref]);

  /**
   * Resize desktop effect : Retrieve the correct value from the window height & the component position
   */
  useEffect(() => {
    if (ref.current && isDesktop) {
      const { top } = ref.current.getBoundingClientRect();

      if (isDesktop) {
        setMaxHeight(windowSize.height - top - 35); // 35 correspond to board margins on desktop
      } else {
        const { top: topBoard } = getBoardBoundingClientRect();

        setMaxHeight(
          windowSize.height - parseInt(headerHeightMobile) - parseInt(boardMarginTop) - (top - topBoard) - 10
        ); // -15 correspond to board margin on mobile (no margin on bottom)
      }
    }

    // eslint-disable-next-line
  }, [windowSize, update]);

  return maxHeight;
}
