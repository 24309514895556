import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Map, Pane, TileLayer, ScaleControl, ZoomControl, GeoJSON, Marker } from "react-leaflet";
import { useMedia } from "../../hooks/useMedia";
import axios from "../../middlewares/axios";
import { assetsPath, envVarToBool, handleKeyPress, translate } from "../../services/tools";
import styled from "styled-components";
import L from "leaflet";
import InteractiveMapObjectInterface from "../../interfaces/InteractiveMapObjectInterface";
import useSelectedFocus from "../../hooks/specific/useSelectedFocusBretagneCanaux";
import { Legend } from "../map/Legend";
import { Geolocation } from "../map/Geolocation";
import { primarycolor } from "../../scss/app.scss";
import { useComponentMaxHeight } from "../../hooks/useComponentMaxHeight";
import { useLocation } from "react-router-dom";
import { UpdatePageView } from "../../tracking";
import MarkerClusterGroup from "react-leaflet-markercluster";

const { REACT_APP_HEADER, REACT_APP_START_POINT, REACT_APP_ZOOM } = process.env;
const width = 340;

const Infos = styled.div`
  background: white;
  position: absolute;
  z-index: 9999;
  width: ${width + "px"};
  top: 20px;
  left: 20px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  outline: none;

  .lc-infos-content {
    padding: 20px;
    margin: 0;
    overflow-y: scroll;
    font-size: 0.875em;

    h3,
    .lc-infos-title {
      font-size: 1.375em;
    }
    h4   {
      font-size: 1.2em;
    }

    .lc-infos-title {
      color: ${primarycolor};
      font-weight: bold;

      :not(.lc-with-type) {
        margin-bottom: 10px;
      }

      & + h4 {
        :not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    img {
      object-fit: cover;
      margin: 10px 0;

      @media screen and (max-width: 360px) {
        object-fit: contain;
        max-height: 250px;
      }
    }

    a {
      color: ${primarycolor};
    }

    h3,
    h4 {
      margin: 0;
    }

    .lc-content {
      margin: 10px 0;
    }

    .lc-content-right {
      margin-top: 10px;
    }

    .lc-content-link,
    .lc-link {
      background: ${primarycolor};
      color: white;
      padding: 5px 10px;
      border-radius: 4px;
      text-decoration: none;
      display: inline-flex;
      margin: 10px 0 0;
    }

    .lc-report {
      margin: 10px 0 0;
      display: flex;
      flex-direction: column;

      a {
        padding: 5px 10px !important;
        margin-top: 5px;
        align-self: flex-start;
        text-decoration: none;
        font-weight: normal !important;
      }
    }
  }

  &.lc-show {
    transform: translateX(0);
  }

  &.lc-hide {
    transform: translateX(-450px);
  }

  @media screen and (max-width: 600px) {
    width: 100vw;
    top: inherit;
    left: 0;
    bottom: 0;

    .lc-infos-content {
      max-height: 60vh !important;
    }
  }
`;

const Title = styled.h3`
  background: ${primarycolor};
  color: white;
  margin: 0;
  padding: 15px 15px 15px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
`;

const Hide = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: background 150ms ease-in-out;

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 14px;
    background: ${primarycolor};
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    background: rgba(0, 0, 0, 0.06);
    &::before,
    &::after {
      background: white;
    }
  }
`;

const Switch = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .lc-checkmark {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: #eee;
  }

  &:hover input ~ .lc-checkmark {
    background-color: #ccc;
  }

  input:checked ~ .lc-checkmark {
    background-color: #232323;
  }

  .lc-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .lc-checkmark:after {
    display: block;
    position: absolute;
  }

  .lc-checkmark:after {
    left: 6px;
    top: 6px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const LegendHiking = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875em;

  .lc-legend-hiking-path {
    width: 48px;
    height: 3px;
    background: ${(props) =>
      props.pathname === "pedestre" ? "#b0252e" : props.pathname === "cyclo" ? "#6e8225" : "#cf7a26"};
    margin-right: 5px;
  }
`;

function LeafletBretagneCanaux(props) {
  const zoom = props.startOnMobile ? JSON.parse(REACT_APP_ZOOM).mobile : JSON.parse(REACT_APP_ZOOM).desktop;

  const center = props.startOnMobile
    ? JSON.parse(REACT_APP_START_POINT).mobile
    : JSON.parse(REACT_APP_START_POINT).desktop;

  const mapRef = useRef();
  const infosRef = useRef();
  const [display, setDisplay] = useState(false);
  const [infos, setInfos] = useState(null);
  const [focus, setFocus] = useState(null);
  const [selectedFocus, setSelectedFocus] = useState(null);
  const [openedFeature, setOpenedFeature] = useState(null);
  const [dataLegend, setDataLegend] = useState(false);
  const [displayLegend, setDisplayLegend] = useState(true);
  const [legendKey, setLegendKey] = useState(0); // State use to update legend when needed... (aka no Math.random())
  const [canRender, setCanRender] = useState(false);
  const [geolocationMarker, setGeolocationMarker] = useState(null);
  const { datasGeojson, datasInfos, datas } = useSelectedFocus(selectedFocus);
  const isDesktop = useMedia();
  const map = mapRef?.current?.leafletElement;
  const componentMaxHeight = useComponentMaxHeight(infosRef);
  const location = useLocation();
  const pathname = location.pathname.replace("/", "");

  // Map attribution
  const attribution =
    '&copy; <a href="https://latitude-cartagene.com" target="_blank" rel="noopener">Latitude-Cartagène</a> | &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener">OpenStreetMap</a>';

  useEffect(() => {
    const loadData = async () => {
      // sera remplacé par moduleData.focusUrl ?
      const focusResponse = await axios.get(`/api/file?folder=map&ext=geojson&name=focus-section`);

      setFocus(focusResponse.data);
    };

    loadData();
    UpdatePageView();
  }, []);

  useEffect(() => {
    if (location.pathname !== "/" && focus && datasGeojson) {
      const section = focus?.features?.find((f) => f.properties.name === pathname && f.geometry.type === "Polygon");

      if (section) {
        document.querySelector(`.${section.properties.name}`).click();
        document.querySelector(`.lc-map-infos h3 div`).click();
        setCanRender(true);
      } else {
        setCanRender(true);
      }
    } else if (focus && datasGeojson) {
      setCanRender(true);
    }

    setLegendKey(legendKey + 1);

    // eslint-disable-next-line
  }, [location, focus, datasGeojson]);

  /**
   * Resize height effect
   */
  useEffect(() => {
    const current = infosRef.current;

    if (current) {
      current.style.maxHeight = componentMaxHeight - 30 + "px";
    }
  }, [componentMaxHeight, infosRef]);

  useLayoutEffect(() => {
    updateMapCenter();

    // eslint-disable-next-line
  }, [display, openedFeature]);

  function updateMapCenter() {
    if (openedFeature) {
      const coords =
        openedFeature.geometry.type === "Point"
          ? openedFeature.geometry.coordinates
          : openedFeature.geometry.coordinates[0];

      const targetPoint = map.project([coords[1], coords[0]], map.getZoom());
      // Offset if we have data to display (cause of info panel)
      const infos = document.querySelector(".lc-map-infos");

      const targetLatLng = map.unproject(
        display
          ? targetPoint.subtract([0, isDesktop ? 0 : -(window.innerHeight - infos.getBoundingClientRect().top) / 2])
          : targetPoint,
        map.getZoom()
      );

      map.setView(targetLatLng, map.getZoom());
    }
  }

  /**
   * Build informations panel from feature properties
   * @param {Object} feature geojson feature
   * @param {String} file geojson file
   */
  function buildInfos(feature, file) {
    const fields = datasInfos[file] || [];

    if (fields.length > 0) {
      setDisplay(true);

      let content =
        fields.length === 1 && fields.includes("content")
          ? ""
          : `<div class="lc-infos-title ${fields.includes("type") ? " lc-with-type" : ""}" >${
              feature.properties.title || translate(file)
            }</div>`;
      const filtered = fields.filter((field) => feature.properties[field]);

      if (filtered.length === 0) {
        content += "Pas de données";
      } else {
        content += filtered
          .map((field, index) => {
            if (["lien_TB", "site_internet"].includes(field)) {
              return `<a class="lc-link" href="${feature.properties[field]}" target="_blank">${translate(
                field
              )}</a><br/>`;
            }

            if (feature.properties[field].toLowerCase() === "oui") {
              return `${["pedestre", "cyclo", "equestre"].includes(field) ? "<br/>" : ""}<strong>${translate(
                field
              )}</strong><br/>`;
            }

            switch (field) {
              case "type":
                return `<h4>${translate(feature.properties[field])}</h4>`;
              case "photo":
                return `<img width="100%" src="${
                  feature.properties[field]
                }" alt onerror="this.previousSibling.remove();this.remove();" />${
                  index < filtered.length - 1 ? "<br/>" : ""
                }`;
              default:
                return `${!["content", "report"].includes(field) ? `<strong>${translate(field)}</strong> : ` : ""}${
                  feature.properties[field]
                }${index < filtered.length - 1 ? "<br/>" : ""}`;
            }
          })
          .join(" ");
      }

      return content;
    }

    setDisplay(false);
    return null;
  }

  function onEachFeature(feature, layer, file) {
    let featInterface = new InteractiveMapObjectInterface(feature, file);

    if (featInterface.properties.popup !== false) {
      layer.bindPopup(featInterface.properties.name || feature.properties.name, {
        closeButton: false,
        autoPan: false,
        autoClose: false,
      });
    }

    if (
      ["multipolygon", "polygon", "multilinestring", "linestring"].includes(featInterface?.geometry?.type.toLowerCase())
    ) {
      layer.setStyle({
        fillColor: feature.properties?.fillColor || "",
        fillOpacity: feature.properties?.fillColor ? 1 : 0,
        color: datas?.[file]?.color || layer.options.data.color || feature.properties?.color || "",
        weight: feature.properties?.weight || datas?.[file]?.weight || 4,
      });
    } else if (["multipoint", "point"].includes(featInterface?.geometry?.type.toLowerCase())) {
      layer.on("click", () => {
        if (featInterface?.properties?.zoomOn === "none") {
          return;
        } else if (featInterface?.properties?.zoomOn === "focus") {
          const section = focus.features.find(
            (f) => f.properties.name === featInterface.properties.name && f.geometry.type === "Polygon"
          );

          map.flyToBounds(new L.LatLngBounds([section.geometry.coordinates[0].map((c) => [c[1], c[0]])]), {
            paddingTopLeft: [0, 0],
            animate: false,
          });

          setInfos(buildInfos(feature, "voie-navigable"));
        } else {
          if (["mobilier", "toilettes-douche"].includes(featInterface.properties.is)) {
            featInterface.properties.id_plaque ? setInfos(buildInfos(feature, file)) : setDisplay(false);
          } else {
            setInfos(buildInfos(feature, file));
          }

          setOpenedFeature(feature);
        }
      });
      layer.on("mouseover", () => {
        layer.openPopup();
      });
      layer.on("mouseout", () => {
        if (!openedFeature || !shallowEqual(feature.properties, openedFeature.properties)) {
          layer.closePopup();
        }
      });
    }
  }

  function focusPointToLayer(feature, latlng) {
    if (map.getZoom() < feature.properties.zoom || map.getZoom() > 11) {
      return;
    }

    return L.marker(latlng, {
      icon: new L.Icon({
        iconSize: feature.properties.size || [60, 30],
        iconAnchor: [30, 15],
        iconUrl: `/assets/images/canaux/${feature.properties.name}.svg`,
        className: feature.properties.name,
      }),
      interactive: feature.properties.zoomOn !== "none",
      pane: "top-pane",
    });
  }

  function pointToLayer(feature, latlng, file) {
    // Filter if needed
    if (datas?.[file]?.filterFromLocation) {
      if (location.pathname !== "/") {
        if (!feature.properties[pathname]) {
          return null;
        }
      }
    }

    let featInterface = new InteractiveMapObjectInterface(feature, file);

    if (featInterface.icon) {
      return L.marker(latlng, {
        icon: featInterface.icon,
        pane: "top-pane",
      });
    }

    // No icon ? Display a basic circle
    return L.circle(latlng, {
      weight: 16,
      fillOpacity: 1,
      color: feature.properties?.color || "#333",
      pane: "top-pane",
    });
  }

  function InteractiveMapObject(props) {
    const { file, data, onEachFeature, pointToLayer } = props;

    if (location.pathname !== "/" && !datas[file].hiking) {
      return null;
    }

    if ((dataLegend && dataLegend[file]) || data.display === "line") {
      if (["multipoint", "point"].includes(data?.features?.[0]?.geometry?.type.toLowerCase())) {
        const zoom = data.zoom || datas[file].zoom;

        return !data.zoom || zoom <= map.getZoom() ? (
          <GeoJSON
            key={file}
            data={data}
            pane="top-pane"
            filter={(feature) => {
              const coords =
                feature.geometry.type === "Point" ? feature.geometry.coordinates : feature.geometry.coordinates[0];

              return map.getBounds().contains([coords[1], coords[0]]);
            }}
            onEachFeature={(feature, layer) => onEachFeature(feature, layer, file)}
            pointToLayer={(feature, latlng) => pointToLayer(feature, latlng, file)}
          />
        ) : null;
      } else if (
        ["multipolygon", "polygon", "multilinestring", "linestring"].includes(
          data?.features?.[0]?.geometry?.type.toLowerCase()
        )
      ) {
        const zoom = data.zoom || datas[file].zoom;

        return !zoom || zoom <= map.getZoom() ? (
          <GeoJSON
            key={file}
            data={data}
            interactive={datas[file].hasOwnProperty("interactive") ? datas[file].interactive : true}
            onEachFeature={(feature, layer) => onEachFeature(feature, layer, file)}
          />
        ) : null;
      }
    }

    return null;
  }

  function shallowEqual(first, second) {
    const first_keys = Object.keys(first);
    const second_keys = Object.keys(second);

    if (first_keys.length !== second_keys.length) {
      return false;
    }

    for (let key of first_keys) {
      if (first[key] !== second[key]) {
        return false;
      }
    }

    return true;
  }

  // Handle move end
  function onMoveEnd() {
    // Update legend data if needed
    const data = Object.keys(datasGeojson)
      .filter(
        (d) =>
          (datasGeojson[d].zoom <= map.getZoom() || datas[d].zoom <= map.getZoom()) &&
          (location.pathname === "/" || (location.pathname !== "/" && datas[d].hiking))
      )
      .reduce((acc, obj) => {
        acc[obj] = dataLegend[obj] !== undefined ? dataLegend[obj] : true;
        return acc;
      }, {});

    setDataLegend(data);

    // If legend data has changed, update the legend
    if (!shallowEqual(dataLegend, data)) {
      setLegendKey(legendKey + 1);
    }

    if (openedFeature) {
      map.eachLayer((layer) => {
        const type = layer.feature?.geometry?.type.toLowerCase();

        if (["multipoint", "point"].includes(type) && layer.feature?.properties?.id) {
          if (map.getBounds().contains(type === "point" ? layer.getLatLng() : layer.getBounds())) {
            if (shallowEqual(layer.feature.properties, openedFeature.properties)) {
              layer.openPopup();
            }
          }
        }
      });
    }
  }

  const hikingTiles = Object.keys(datas)
    .filter((k) => k === pathname)
    .reduce((acc, k) => {
      if (datas[k].tiles) {
        acc.push(k);
      }

      return acc;
    }, []);

  function toggleLegend(active = false) {
    for (const key of Object.keys(dataLegend)) {
      dataLegend[key] = active;
    }

    setLegendKey(legendKey + 1);
    setDataLegend({ ...dataLegend });

    active && setDisplay(false);
  }

  return (
    <>
      <Infos tabIndex="0" className={"lc-map-infos" + (display ? " lc-show" : " lc-hide")}>
        <Title>
          {translate("informations")}
          <Hide
            role="button"
            tabIndex="0"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setDisplay(false);
                setSelectedFocus(null);
                setOpenedFeature(null);
              }
            }}
            onClick={() => {
              setDisplay(false);
              setSelectedFocus(null);
              setOpenedFeature(null);
            }}
          />
        </Title>
        <div className="lc-infos-content" ref={infosRef} dangerouslySetInnerHTML={{ __html: infos }} />
      </Infos>
      <div className="lc-map">
        <Map
          ref={mapRef}
          center={center}
          className={
            "lc-mapContainer" +
            (envVarToBool(REACT_APP_HEADER) ? " lc-with-header" : "") +
            (!props.showBoard ? " lc-no-board" : "")
          }
          zoom={zoom}
          minZoom={+JSON.parse(REACT_APP_ZOOM)["min"]}
          maxZoom={+JSON.parse(REACT_APP_ZOOM)["max"]}
          zoomControl={false}
          onmoveend={onMoveEnd}
          onclick={() => {
            setOpenedFeature(null);
            setDisplay(false);
          }}
        >
          {isDesktop && (
            <>
              <ScaleControl position={"bottomright"} imperial={false} />
              <ZoomControl position={"bottomright"} />
            </>
          )}
          {!canRender && <div className="lc-map-hide" />}
          {(Object.keys(dataLegend).filter((k) => !datas[k].hasOwnProperty("tiles")).length ||
            (pathname !== "" && map?.getZoom() > 8)) && (
            <Legend
              key={legendKey}
              isPoi={true}
              defaultOpen={displayLegend}
              setShow={setDisplayLegend}
              isNotMain={true}
            >
              <div className="lc-legend-pois-title">{translate("legend")}</div>
              {translate(`legend-${pathname}`) && map?.getZoom() > 8 && (
                <LegendHiking pathname={pathname}>
                  <div className="lc-legend-hiking-path" />
                  {translate(`legend-${pathname}`)}
                </LegendHiking>
              )}
              <div className="lc-legend-pois">
                {Object.keys(dataLegend)
                  .filter((k) => !datas[k].hasOwnProperty("tiles"))
                  .map((key) => (
                    <div key={key} className="lc-legend-poi">
                      <Switch>
                        <input
                          type="checkbox"
                          checked={dataLegend[key]}
                          onChange={(e) => {
                            dataLegend[key] = e.target.checked;

                            // Close and hide infos if openedFeature is the same type !
                            if (openedFeature?.properties.is === key) {
                              setOpenedFeature(null);
                              setDisplay(false);
                            }

                            setLegendKey(legendKey + 1);
                            setDataLegend({ ...dataLegend });
                          }}
                        />
                        <span className="lc-checkmark"></span>
                        {key === "petites-boucles" ? (
                          <img src={assetsPath(`/assets/images/legend/${key}-${pathname}.svg`)} alt="" />
                        ) : (
                          <img src={assetsPath(`/assets/images/legend/${key}.svg`)} alt="" />
                        )}
                        {translate(key)}
                      </Switch>
                    </div>
                  ))}
                {Object.keys(dataLegend).filter((k) => !datas[k].hasOwnProperty("tiles")).length > 0 && (
                  <div className="lc-legend-toggles">
                    <div
                      className="lc-button"
                      role="button"
                      tabIndex="0"
                      onClick={() => toggleLegend(false)}
                      onKeyPress={(e) => handleKeyPress(e, () => toggleLegend(false))}
                    >
                      {translate("legend-toggle-hide")}
                    </div>
                    <div
                      className="lc-button"
                      role="button"
                      tabIndex="0"
                      onClick={() => toggleLegend(true)}
                      onKeyPress={(e) => handleKeyPress(e, () => toggleLegend(true))}
                    >
                      {translate("legend-toggle-show")}
                    </div>
                  </div>
                )}
              </div>
            </Legend>
          )}
          {!isDesktop && (
            <Geolocation
              onGeolocationSuccess={(position) => {
                setGeolocationMarker([position.coords.latitude, position.coords.longitude]);
                map.flyTo([position.coords.latitude, position.coords.longitude], 12);
              }}
            />
          )}
          {!isDesktop && geolocationMarker && (
            <Marker
              position={geolocationMarker}
              icon={
                new L.icon({
                  iconUrl: `/assets/images/geoloc.svg`,
                  iconSize: [30, 30],
                  iconAnchor: [15, 30],
                })
              }
            />
          )}
          <TileLayer
            url={`https://tiles.lc.tools/services/preprod_bretagne_fond/tiles/{z}/{x}/{y}.png`}
            attribution={attribution}
          />
          <Pane style={{ zIndex: 650 }}>
            {hikingTiles.map((tiles) => (
              <TileLayer
                key={tiles}
                url={`https://tiles.lc.tools/services/preprod_bretagne_canaux_${tiles}/tiles/{z}/{x}/{y}.png`}
                minZoom={datas[tiles].minZoom}
              />
            ))}
          </Pane>
          <Pane name="top-pane" style={{ zIndex: 652 }}></Pane>
          {focus && (
            <GeoJSON
              key={Math.random()}
              data={focus}
              onEachFeature={(feature, layer) => onEachFeature(feature, layer, "focus")}
              pointToLayer={focusPointToLayer}
              interactive={false}
            />
          )}
          <MarkerClusterGroup
            key={"clusterGroup"}
            clusterPane="top-pane"
            removeOutsideVisibleBounds
            showCoverageOnHover={false}
            zoomToBoundsOnClick={true}
            spiderfyOnMaxZoom={true}
            iconCreateFunction={(cluster) => {
              return L.divIcon({ html: cluster.getChildCount(), className: "lc-cluster" });
            }}
          >
            {Object.keys(datasGeojson)
              .filter((k) => !datas[k].tiles)
              .map((dataGeojson) => (
                <InteractiveMapObject
                  key={dataGeojson}
                  file={dataGeojson}
                  data={datasGeojson[dataGeojson]}
                  onEachFeature={onEachFeature}
                  pointToLayer={pointToLayer}
                />
              ))}
          </MarkerClusterGroup>

          <Pane style={{ zIndex: 651 }}>
            <TileLayer url={`https://tiles.lc.tools/services/bretagne-typo-png/tiles/{z}/{x}/{y}.png`} />
          </Pane>
        </Map>
      </div>
    </>
  );
}

export default LeafletBretagneCanaux;
