import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "../middlewares/axios";
import { assetsPath, translate } from "../services/tools";
import { UploadArea, UploadContent, UploadName } from "./styled/UIUploadArea";

const { REACT_APP_ADMIN, REACT_APP_PROJECT } = process.env;

const Admin = () => {
  const [indicator, setIndicator] = useState({ type: null, message: null });
  const [isLoading, setIsLoading] = useState(false);

  const displayUpdateStatus = (status) => {
    switch (status) {
      case "loading":
        setIndicator({ type: "loading", message: translate("admin-update-loading") });
        break;
      case "success":
        setIndicator({ type: "success", message: translate("admin-update-success") });
        break;
      default:
        setIndicator({ type: "error", message: translate("admin-update-error") });
        break;
    }
  };

  const updateXlsxFromDrive = async (id) => {
    displayUpdateStatus("loading");
    axios
      .get(`/api/admin?id=${id}`)
      .then((resp) => {
        displayUpdateStatus("success");
      })
      .catch((err) => {
        displayUpdateStatus("error");
      });
  };

  useEffect(() => {
    setIsLoading(indicator.type === "loading");
  }, [indicator]);

  return JSON.parse(REACT_APP_ADMIN)?.filter((e) => e.type === "valkyrie-only")?.length ? (
    <div className="lc-admin-valkyrie" key="admin-valkyrie">
      <iframe
        title="valkyrie"
        src={`https://valkyrie.latitude-cartagene.com/${REACT_APP_PROJECT}`}
        frameborder="0"
        referrerpolicy="origin"
        class="frame"
      ></iframe>
    </div>
  ) : (
    <>
      <div className="lc-admin-header">
        <span>{translate("admin-title")}</span>
      </div>
      {indicator.type && (
        <div
          className={`lc-admin-message ${
            indicator.type && indicator.type === "error"
              ? " message-error"
              : indicator.type === "success"
              ? " message-success"
              : " message-loading"
          }`}
        >
          {indicator.message}
          {isLoading && (
            <div className="lc-loading" data-lc-loading>
              <img src={assetsPath("/assets/images/loading.gif")} width={30} alt={translate("loading")} />
            </div>
          )}
        </div>
      )}
      <div className="lc-admin-content">
        {JSON.parse(REACT_APP_ADMIN)?.map((element, index) => {
          switch (element.type) {
            case "xlsxFromDrive":
              return (
                <UploadArea key={`admin-${index}`}>
                  <UploadContent>
                    <UploadName>{translate(`admin-update-name-${element.name}`)}</UploadName>
                    <span>{translate(`admin-update-${element.type}`)}</span>
                    <label onClick={() => updateXlsxFromDrive(element.id)} htmlFor="file">
                      {translate("admin-update-launch-button")}
                    </label>
                  </UploadContent>
                </UploadArea>
              );
            default:
              return null;
          }
        })}
      </div>
    </>
  );
};

export default withRouter(Admin);
