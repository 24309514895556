import L from "leaflet";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useLeafletMap } from "use-leaflet";
import styled from "styled-components";
import { useMedia } from "../../hooks/useMedia";
import { assetsPath, translate } from "../../services/tools";
import { primarycolor } from "../../scss/app.scss";
import Tippy from "@tippy.js/react";
import { appStore } from "../../store";

const LegendContainer = styled.div`
  position: ${(props) => props.isDesktop && "absolute"};
  bottom: -65px;
  right: 40px;
  background-color: white;
  background-image: ${(props) => props.image && `url(${props.image})`};
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  width: ${(props) => props.image && (props.isDesktop ? "35em" : "calc(100vw - 55px)")};
  height: ${(props) => props.image && "20em"};
  font-size: 1.25em;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-width: max-content;
  border: 2px solid rgba(0, 0, 0, 0.2);
  @media (max-width: 768px) {
    font-size: 1em;
    padding: 10px;
  }
`;

const LegendToggle = styled.div`
  position: ${(props) => !props.isDesktop && "absolute"};
  right: ${(props) => !props.isDesktop && "0"};
  top: ${(props) => props.isNotMain && "25px"};
  background-color: ${primarycolor};
  background-image: url(${(props) => assetsPath(props.legendIcon || "/assets/images/legend-item.svg")});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  margin-bottom: ${(props) => props.show && "-10px"};
  margin-right: -2px;
  padding: 13px;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

const Title = styled.div`
  font-weight: bold;
  margin: -5px 0 5px;
`;

const Line = styled.div`
  width: 65px;
  height: ${(props) => (props.line ? "6px" : "3px")};
  background: ${(props) => (props.line ? `#${props.line.color}` : "#888")};
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Stop = ({ r = 3, c = 6, sWidth = 1, stroke = "#888", fill = "white", size = 12 }) => (
  <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
    <ellipse ry={r} rx={r} cy={c} cx={c} strokeWidth={sWidth} stroke={stroke} fill={fill} />
  </svg>
);

const Current = styled.div`
  display: flex;
  align-items: center;
`;

const Future = styled.div`
  display: flex;
  align-items: center;
`;

const DivLegend = (props) => {
  if (props.line) {
    return (
      <LegendContainer isDesktop={props.isDesktop}>
        <Title>Légende</Title>
        <Future>
          <Line line={props.line}>
            <Stop r="5" c="7" sWidth="2" stroke={"#" + props.line.color} size="14" />
          </Line>
          {translate("legend-future")}
        </Future>
        <Current>
          <Line>
            <Stop />
          </Line>
          {translate("legend-current")}
        </Current>
      </LegendContainer>
    );
  } else {
    return null;
  }
};

const PoiLegend = (props) => {
  return <LegendContainer {...props}>{props.children}</LegendContainer>;
};

const Image = (props) => {
  return <LegendContainer {...props}></LegendContainer>;
};

export function Legend(props) {
  const { image, isPoi, legendIcon, defaultOpen = true, isNotMain } = props;
  const map = useLeafletMap();
  const isDesktop = useMedia();
  const [show, setShow] = useState(defaultOpen);

  useEffect(() => {
    const legend = L.control({
      position: isDesktop ? "bottomright" : "topleft",
    });

    const legendToggle = L.control({
      position: isDesktop ? "bottomright" : "topright",
    });

    const div = L.DomUtil.create("div", "");
    const divToggle = L.DomUtil.create("div", "");

    L.DomEvent.disableClickPropagation(div);
    L.DomEvent.disableClickPropagation(divToggle);

    legend.onAdd = function () {
      if (!image && !isPoi) {
        ReactDOM.render(<DivLegend {...props} isDesktop={isDesktop} className="lc-legend-content" />, div);
      } else if (isPoi) {
        ReactDOM.render(<PoiLegend {...props} isDesktop={isDesktop} className="lc-legend-content" />, div);
      } else {
        ReactDOM.render(<Image {...props} isDesktop={isDesktop} image={image} className="lc-legend-content" />, div);
      }

      return div;
    };

    legendToggle.onAdd = () => {
      ReactDOM.render(
        <Tippy
          theme={"latitude"}
          touch={["hold", 500]}
          placement={"left"}
          boundary="window"
          content={translate(show ? "legend-close" : "legend-open")}
        >
          <LegendToggle
            legendIcon={legendIcon}
            show={show}
            isDesktop={isDesktop}
            isNotMain={isNotMain}
            onClick={() => {
              setShow(!show);
              props.setShow && props.setShow(!show);
            }}
          />
        </Tippy>,
        divToggle
      );
      return divToggle;
    };

    show && legend.addTo(map);
    legendToggle.addTo(map);

    return () => {
      show && map.removeControl(legend);
      map.removeControl(legendToggle);
    };

    // eslint-disable-next-line
  }, [isDesktop, show, props.image, appStore.getState().app]);

  return null;
}
